.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal__container {
    background-color: #fff;
    padding: 20px 30px;
    width: 640px;
    max-height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
    position: relative;

    &.large {
        width: 1330px;
    }
  }
  
  .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25;
    color: #00449e;
    box-sizing: border-box;
  }
  
  .modal__close {
    background: transparent;
    border: 0;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
  
  .modal__header .modal__close:before { content: "\2715"; font-size: 16px; }
  
  .modal__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.5;
    color: rgba(0,0,0,.8);
  }
  
  .modal__btn {
    font-size: .875rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background-color: #e6e6e6;
    color: rgba(0,0,0,.8);
    border-radius: .25rem;
    border-style: none;
    border-width: 0;
    cursor: pointer;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
    line-height: 1.15;
    margin: 0;
    will-change: transform;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    transition: -webkit-transform .25s ease-out;
    transition: transform .25s ease-out;
    transition: transform .25s ease-out,-webkit-transform .25s ease-out;
  }
  
  .modal__btn:focus, .modal__btn:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  
  .modal__btn-primary {
    background-color: #00449e;
    color: #fff;
  }
  
  
  
  /**************************\
    Demo Animation Style
  \**************************/
  @keyframes mmfadeIn {
      from { opacity: 0; }
        to { opacity: 1; }
  }
  
  @keyframes mmfadeOut {
      from { opacity: 1; }
        to { opacity: 0; }
  }
  
  @keyframes mmslideIn {
    from { transform: translateY(15%); }
      to { transform: translateY(0); }
  }
  
  @keyframes mmslideOut {
      from { transform: translateY(0); }
      to { transform: translateY(-10%); }
  }
  
  .micromodal-slide {
    display: none;
  }
  
  .micromodal-slide.is-open {
    display: block;
  }
 