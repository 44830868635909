.note-box{
    padding: 26px 52px;
    text-align: left;

    p {
        margin: 0;
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;
        color: #000000;
    }

    &.note-green{
        background-color: #CFF0DB;
    }
}