/*--------------------------------------------------------------
* (DEPRECATED)
* Theme Colors & Variables - only use if not using Agency Base Plugin for Oxygen
--------------------------------------------------------------*/
/* Theme Colors */
$brand-1: #331832;
$brand-2: #26af5b;
$brand-3: #e31837;
$brand-4: #febe10;

$accent-1: #fff;
$accent-2: #fff;
$gradient-1-c1: #fff;
$gradient-1-c2: #fff;
$gradient-2-c1: #fff;
$gradient-2-c2: #fff;

$button-1: #febe10;
$button-1-text: #000;
$button-2: #331832;
$button-2-text: #fff;
$button-3: #26af5b;
$button-3-text: #fff;

$black: #000;
$dark-1: #000;
$dark-2: #000;
$medium: #000;
$light-1: #000;
$light-2: #000;
$white: #f2f2f2;

$link-color: #0000ff;
$link-hover-color: #0000a0;
$disabled: #757575;

$body: #222;


/* Derivatives */
$brand-1-l-80: lighten( $brand-1, 80% );
$brand-1-l-60: lighten( $brand-1, 60% );
$brand-1-l-40: lighten( $brand-1, 40% );
$brand-1-l-20: lighten( $brand-1, 20% );
$brand-1-d-20: darken( $brand-1, 20% );
$brand-1-d-40: darken( $brand-1, 40% );
$brand-1-d-60: darken( $brand-1, 60% );
$brand-1-d-80: darken( $brand-1, 80% );

$brand-2-l-80: lighten( $brand-2, 80% );
$brand-2-l-60: lighten( $brand-2, 60% );
$brand-2-l-40: lighten( $brand-2, 40% );
$brand-2-l-20: lighten( $brand-2, 20% );
$brand-2-d-20: darken( $brand-2, 20% );
$brand-2-d-40: darken( $brand-2, 40% );
$brand-2-d-60: darken( $brand-2, 60% );
$brand-2-d-80: darken( $brand-2, 80% );

$brand-3-l-80: lighten( $brand-3, 80% );
$brand-3-l-60: lighten( $brand-3, 60% );
$brand-3-l-40: lighten( $brand-3, 40% );
$brand-3-l-20: lighten( $brand-3, 20% );
$brand-3-d-20: darken( $brand-3, 20% );
$brand-3-d-40: darken( $brand-3, 40% );
$brand-3-d-60: darken( $brand-3, 60% );
$brand-3-d-80: darken( $brand-3, 80% );

$brand-4-l-80: lighten( $brand-4, 80% );
$brand-4-l-60: lighten( $brand-4, 60% );
$brand-4-l-40: lighten( $brand-4, 40% );
$brand-4-l-20: lighten( $brand-4, 20% );
$brand-4-d-20: darken( $brand-4, 20% );
$brand-4-d-40: darken( $brand-4, 40% );
$brand-4-d-60: darken( $brand-4, 60% );
$brand-4-d-80: darken( $brand-4, 80% );

$button-1-l-20: lighten( $button-1, 20% );
$button-1-l-10: lighten( $button-1, 10% );
$button-1-d-10: darken( $button-1, 10% );
$button-1-d-20: darken( $button-1, 20% );

$button-2-l-20: lighten( $button-2, 20% );
$button-2-l-10: lighten( $button-2, 10% );
$button-2-d-10: darken( $button-2, 10% );
$button-2-d-20: darken( $button-2, 20% );

$button-3-l-20: lighten( $button-3, 20% );
$button-3-l-10: lighten( $button-3, 10% );
$button-3-d-10: darken( $button-3, 10% );
$button-3-d-20: darken( $button-3, 20% );


$black-l-80: lighten( $black, 80% );
$black-l-60: lighten( $black, 60% );
$black-l-40: lighten( $black, 40% );
$black-l-20: lighten( $black, 20% );

$dark-1-l-80: lighten( $dark-1, 80% );
$dark-1-l-60: lighten( $dark-1, 60% );
$dark-1-l-40: lighten( $dark-1, 40% );
$dark-1-l-20: lighten( $dark-1, 20% );
$dark-1-d-20: darken( $dark-1, 20% );
$dark-1-d-40: darken( $dark-1, 40% );
$dark-1-d-60: darken( $dark-1, 60% );
$dark-1-d-80: darken( $dark-1, 80% );

$dark-2-l-80: lighten( $dark-2, 80% );
$dark-2-l-60: lighten( $dark-2, 60% );
$dark-2-l-40: lighten( $dark-2, 40% );
$dark-2-l-20: lighten( $dark-2, 20% );
$dark-2-d-20: darken( $dark-2, 20% );
$dark-2-d-40: darken( $dark-2, 40% );
$dark-2-d-60: darken( $dark-2, 60% );
$dark-2-d-80: darken( $dark-2, 80% );

$medium-l-80: lighten( $medium, 80% );
$medium-l-60: lighten( $medium, 60% );
$medium-l-40: lighten( $medium, 40% );
$medium-l-20: lighten( $medium, 20% );
$medium-d-20: darken( $medium, 20% );
$medium-d-40: darken( $medium, 40% );
$medium-d-60: darken( $medium, 60% );
$medium-d-80: darken( $medium, 80% );

$light-1-l-80: lighten( $light-1, 80% );
$light-1-l-60: lighten( $light-1, 60% );
$light-1-l-40: lighten( $light-1, 40% );
$light-1-l-20: lighten( $light-1, 20% );
$light-1-d-20: darken( $light-1, 20% );
$light-1-d-40: darken( $light-1, 40% );
$light-1-d-60: darken( $light-1, 60% );
$light-1-d-80: darken( $light-1, 80% );

$light-2-l-80: lighten( $light-2, 80% );
$light-2-l-60: lighten( $light-2, 60% );
$light-2-l-40: lighten( $light-2, 40% );
$light-2-l-20: lighten( $light-2, 20% );
$light-2-d-20: darken( $light-2, 20% );
$light-2-d-40: darken( $light-2, 40% );
$light-2-d-60: darken( $light-2, 60% );
$light-2-d-80: darken( $light-2, 80% );

$white-d-20: darken( $white, 20% );
$white-d-40: darken( $white, 40% );
$white-d-60: darken( $white, 60% );
$white-d-80: darken( $white, 80% );

:root {
	--brand-1: #{$brand-1};
	--brand-1-lighten-80: #{$brand-1-l-80};
	--brand-1-lighten-60: #{$brand-1-l-60};
	--brand-1-lighten-40: #{$brand-1-l-40};
	--brand-1-lighten-20: #{$brand-1-l-20};
	--brand-1-darken-20: #{$brand-1-d-20};
	--brand-1-darken-40: #{$brand-1-d-40};
	--brand-1-darken-60: #{$brand-1-d-60};
	--brand-1-darken-80: #{$brand-1-d-80};
	--brand-2: #{$brand-2};
	--brand-2-lighten-80: #{$brand-2-l-80};
	--brand-2-lighten-60: #{$brand-2-l-60};
	--brand-2-lighten-40: #{$brand-2-l-40};
	--brand-2-lighten-20: #{$brand-2-l-20};
	--brand-2-darken-20: #{$brand-2-d-20};
	--brand-2-darken-40: #{$brand-2-d-40};
	--brand-2-darken-60: #{$brand-2-d-60};
	--brand-2-darken-80: #{$brand-2-d-80};
	--brand-3: #8224e3;
	--brand-3-lighten-80: #{$brand-3-l-80};
	--brand-3-lighten-60: #{$brand-3-l-60};
	--brand-3-lighten-40: #{$brand-3-l-40};
	--brand-3-lighten-20: #{$brand-3-l-20};
	--brand-3-darken-20: #{$brand-3-d-20};
	--brand-3-darken-40: #{$brand-3-d-40};
	--brand-3-darken-60: #{$brand-3-d-60};
	--brand-3-darken-80: #{$brand-3-d-80};
	--brand-4: #528cbf;
	--brand-4-lighten-80: #{$brand-4-l-80};
	--brand-4-lighten-60: #{$brand-4-l-60};
	--brand-4-lighten-40: #{$brand-4-l-40};
	--brand-4-lighten-20: #{$brand-4-l-20};
	--brand-4-darken-20: #{$brand-4-d-20};
	--brand-4-darken-40: #{$brand-4-d-40};
	--brand-4-darken-60: #{$brand-4-d-60};
	--brand-4-darken-80: #{$brand-4-d-80};
	
    
    --accent-1: #{$accent-1};
	--accent-2: #{$accent-2};
	--gradient-1-c1: #{$gradient-1-c1};
	--gradient-1-c2: #{$gradient-1-c2};
	--gradient-2-c1: #{$gradient-2-c1};
	--gradient-2-c2: #{$gradient-2-c2};
	--button-1: #{$button-1};
	--button-1-lighten-20: #{$button-1-l-20};
	--button-1-lighten-10: #{$button-1-l-10};
	--button-1-darken-10: #{$button-1-d-10};
	--button-1-darken-20: #{$button-1-d-20};
	--button-1-text: #{$button-1-text};
	--button-2: #{$button-2};
	--button-2-lighten-20: #{$button-2-l-20};
	--button-2-lighten-10: #{$button-2-l-10};
	--button-2-darken-10: #{$button-2-d-10};
	--button-2-darken-20: #{$button-2-d-20};
	--button-2-text: #{$button-2-text};
	--button-3: #{$button-3};
    --button-3-lighten-20: #{$button-3-l-20};
	--button-3-lighten-10: #{$button-3-l-10};
	--button-3-darken-10: #{$button-3-d-10};
	--button-3-darken-20: #{$button-3-d-20};
	--button-3-text: #{$button-3-text};
	--black: #{$black};
	--black-lighten-80: #{$black-l-80};
	--black-lighten-60: #{$black-l-60};
	--black-lighten-40: #{$black-l-40};
	--black-lighten-20: #{$black-l-20};
	--dark-1: #{$dark-1};
	--dark-1-lighten-80: #{$dark-1-l-80};
	--dark-1-lighten-60: #{$dark-1-l-60};
	--dark-1-lighten-40: #{$dark-1-l-40};
	--dark-1-lighten-20: #{$dark-1-l-20};
	--dark-1-darken-20: #{$dark-1-d-20};
	--dark-1-darken-40: #{$dark-1-d-40};
	--dark-1-darken-60: #{$dark-1-d-60};
	--dark-1-darken-80: #{$dark-1-d-80};
	--dark-2: #{$dark-2};
	--dark-2-lighten-80: #{$dark-2-l-80};
	--dark-2-lighten-60: #{$dark-2-l-60};
	--dark-2-lighten-40: #{$dark-2-l-40};
	--dark-2-lighten-20: #{$dark-2-l-20};
	--dark-2-darken-20: #{$dark-2-d-20};
	--dark-2-darken-40: #{$dark-2-d-40};
	--dark-2-darken-60: #{$dark-2-d-60};
	--dark-2-darken-80: #{$dark-2-d-80};
	--medium: #{$medium};
	--medium-lighten-80: #{$medium-l-80};
	--medium-lighten-60: #{$medium-l-60};
	--medium-lighten-40: #{$medium-l-40};
	--medium-lighten-20: #{$medium-l-20};
	--medium-darken-20: #{$medium-d-20};
	--medium-darken-40: #{$medium-d-40};
	--medium-darken-60: #{$medium-d-60};
	--medium-darken-80: #{$medium-d-80};
	--light-1: #{$light-1};
	--light-1-lighten-80: #{$light-1-l-80};
	--light-1-lighten-60: #{$light-1-l-60};
	--light-1-lighten-40: #{$light-1-l-40};
	--light-1-lighten-20: #{$light-1-l-20};
	--light-1-darken-20: #{$light-1-d-20};
	--light-1-darken-40: #{$light-1-d-40};
	--light-1-darken-60: #{$light-1-d-60};
	--light-1-darken-80: #{$light-1-d-80};
	--light-2: #{$light-2};
    --light-2-lighten-80: #{$light-2-l-80};
	--light-2-lighten-60: #{$light-2-l-60};
	--light-2-lighten-40: #{$light-2-l-40};
	--light-2-lighten-20: #{$light-2-l-20};
	--light-2-darken-20: #{$light-2-d-20};
	--light-2-darken-40: #{$light-2-d-40};
	--light-2-darken-60: #{$light-2-d-60};
	--light-2-darken-80: #{$light-2-d-80};
	--white: #{$white};
	--white-darken-80: #{$white-d-80};
	--white-darken-60: #{$white-d-60};
	--white-darken-40: #{$white-d-40};
	--white-darken-20: #{$white-d-20};
	--link-color: #{$link-color};
	--link-hover-color: #{$link-hover-color};
	--disabled: #{$disabled};
	--body: #{$body};
}