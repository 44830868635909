/*--------------------------------------------------------------
# Header Styles
--------------------------------------------------------------*/
.app-header{
    display: grid;
    grid-template-columns: 250px 1fr 250px;
    padding-top: 30px;
}

.header__logo{
    width: 100%;
    max-width: 240px;
    display: flex;
    align-items: center;
    max-height: 51px;
}

.header__timeline, .app-timeline{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.header__timeline{
    align-items: center;
}

.app-timeline{
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: space-around;
}

.header__contact-info{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-info__number{
    font-size: 2.6rem;
    text-decoration: none;
}