/* CSS GRID - COLUMNS */
.c-columns-2-auto {
    display: grid!important;
    grid-template-columns: auto auto;
    width: 100%;
}
.c-columns-6 {
    display: grid!important;
    grid-template-columns: repeat(6, 1fr);
    width: 100%;
}
.c-columns-5 {
    display: grid!important;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
}
.c-columns-4 {
    display: grid!important;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
}
.c-columns-3 {
    display: grid!important;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
}
.c-columns-2 {
    display: grid!important;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
}
.c-columns-3-2 {
    display: grid!important;
    grid-template-columns: 3fr 2fr;
    width: 100%;
}
.c-columns-2-3 {
    display: grid!important;
    grid-template-columns: 2fr 3fr;
    width: 100%;
}
.c-columns-1-2 {
    display: grid!important;
    grid-template-columns: 1fr 2fr;
    width: 100%;
}
.c-columns-2-1 {
    display: grid!important;
    grid-template-columns: 2fr 1fr;
    width: 100%;
}
.c-columns-3-1 {
    display: grid!important;
    grid-template-columns: 3fr 1fr;
    width: 100%;
}
.c-columns-1-3 {
    display: grid!important;
    grid-template-columns: 1fr 3fr;
    width: 100%;
}
@media only screen and (max-width: 1399px) {
    .c-columns-xl-5 {
        grid-template-columns: repeat(5, 1fr);
   }
    .c-columns-xl-4 {
        grid-template-columns: repeat(4, 1fr);
   }
    .c-columns-xl-3 {
        grid-template-columns: repeat(3, 1fr);
   }
    .c-columns-xl-2 {
        grid-template-columns: repeat(2, 1fr);
   }
    .c-columns-xl-1 {
        grid-template-columns: 1fr;
   }
}
@media only screen and (max-width: 991px) {
    .c-columns-l-4 {
        grid-template-columns: repeat(4, 1fr);
   }
    .c-columns-l-3 {
        grid-template-columns: repeat(3, 1fr);
   }
    .c-columns-l-2 {
        grid-template-columns: repeat(2, 1fr);
   }
    .c-columns-l-1 {
        grid-template-columns: 1fr;
   }
}
@media only screen and (max-width: 767px) {
    .c-columns-m-3 {
        grid-template-columns: repeat(3, 1fr);
   }
    .c-columns-m-2 {
        grid-template-columns: repeat(2, 1fr);
   }
    .c-columns-m-1 {
        grid-template-columns: 1fr;
   }
}
@media only screen and (max-width: 479px) {
    .c-columns-s-2 {
        grid-template-columns: repeat(2, 1fr);
   }
    .c-columns-s-1 {
        grid-template-columns: 1fr;
   }
}
/* CSS GRID - GROW & GAPS*/
.c-row-grow {
    grid-row-start: span 2;
}
.c-column-grow {
    grid-column-start: span 2;
}
@media (max-width: 991px) {
    .c-row-grow {
        grid-row-start: span 1;
   }
    .c-column-grow {
        grid-column-start: span 1;
   }
}
.c-columns-gap-xxl {
    grid-gap: 12vmin;
}
.c-columns-gap-xl {
    grid-gap: 8vmin;
}
.c-columns-gap-l {
    grid-gap: 5vmin;
}
.c-columns-gap-m {
    grid-gap: 1.25rem;
}
.c-columns-gap-s {
    grid-gap: 0.5rem;
}
/* WIDTHS */
.c-w-100 {
    width:100%;
}
.c-w-90 {
    width: 90%;
}
.c-w-80 {
    width: 80%;
}
.c-w-75 {
    width: 75%;
}
.c-w-70 {
    width: 70%;
}
.c-w-60 {
    width: 60%;
}
.c-w-50 {
    width: 50%;
}
.c-w-40 {
    width: 40%;
}
.c-w-33 {
    width: 33%;
}
.c-w-30 {
    width: 30%;
}
.c-w-25 {
    width: 25%;
}
.c-w-20 {
    width: 20%;
}
.c-w-10 {
    width: 10%;
}
/* BACKGROUND COLORS */
.c-bg-gradient-1 {
    background-image: linear-gradient(90deg, var(--gradient-1-c1), var(--gradient-1-c2));
}
.c-bg-gradient-2 {
    background-image: linear-gradient(90deg, var(--gradient-2-c1), var(--gradient-2-c2));
}
.c-bg-brand-1 {
    background-color: var(--brand-1);
}
.c-bg-brand-2 {
    background-color: var(--brand-2);
}
.c-bg-brand-3 {
    background-color: var(--brand-3);
}
.c-bg-brand-4 {
    background-color: var(--brand-4);
}
.c-bg-black {
    background-color: var(--black);
}
.c-bg-dark-1 {
    background-color: var(--dark-1);
}
.c-bg-dark-2 {
    background-color: var(--dark-2);
}
.c-bg-med {
    background-color: var(--medium);
}
.c-bg-light-1 {
    background-color: var(--light-1);
}
.c-bg-light-2 {
    background-color: var(--light-2);
}
.c-bg-white {
    background-color: var(--white);
}
.c-bg-accent-1 {
    background-color: var(--accent-1);
}
.c-bg-accent-2 {
    background-color: var(--accent-2);
}
/* HEADINGS */
.c-h1 {
    font-size: var(--title-size-1);
}
.c-h2 {
    font-size: var(--title-size-2);
}
.c-h3 {
    font-size: var(--title-size-3);
}
.c-h4 {
    font-size: var(--title-size-4);
}
.c-h5 {
    font-size: var(--title-size-5);
}
.c-h6 {
    font-size: var(--title-size-6);
}
/* FONT SIZE */
.c-fs-xxs {
    font-size: 0.625rem;
}
.c-fs-xs {
    font-size: 0.75rem;
}
.c-fs-s {
    font-size: 0.875rem;
}
.c-fs-2 {
    font-size: 1rem;
}
.c-fs-3 {
    font-size: 1.125rem;
}
.c-fs-4 {
    font-size: 1.25rem;
}
.c-fs-5 {
    font-size: 1.375rem;
}
.c-fs-6 {
    font-size: 1.5rem;
}
.c-fs-7 {
    font-size: 1.75rem;
}
.c-fs-8 {
    font-size: 2rem;
}
.c-fs-9 {
    font-size: 2.25rem;
}
.c-fs-10 {
    font-size: 2.5rem;
}
.c-fs-11 {
    font-size: 2.75rem;
}
/* FONT WEIGHT */
.c-fw-100 {
    font-weight: 100;
}
.c-fw-200 {
    font-weight: 200;
}
.c-fw-300 {
    font-weight: 300;
}
.c-fw-400 {
    font-weight: 400;
}
.c-fw-500 {
    font-weight: 500;
}
.c-fw-600 {
    font-weight: 600;
}
.c-fw-700 {
    font-weight: 700;
}
.c-fw-800 {
    font-weight: 800;
}
.c-fw-900 {
    font-weight: 900;
}
/* FONT STYLES */
.c-fs-under {
    text-decoration: underline;
}
.c-fs-cap {
    text-transform: capitalize;
}
.c-fs-lower {
    text-transform: lowercase;
}
.c-fs-upper {
    text-transform: uppercase;
}
.c-fs-strike {
    text-decoration: line-through;
}
/* TEXT COLOR */
.c-tc-brand-1 {
    color: var(--brand-1);
}
.c-tc-brand-2 {
    color: var(--brand-2);
}
.c-tc-brand-3 {
    color: var(--brand-3);
}
.c-tc-brand-4 {
    color: var(--brand-4);
}
.c-tc-black {
    color: var(--black);
}
.c-tc-dark-1 {
    color: var(--dark-1);
}
.c-tc-dark-2 {
    color: var(--dark-2);
}
.c-tc-med {
    color: var(--medium);
}
.c-tc-light-1 {
    color: var(--light-1);
}
.c-tc-light-2 {
    color: var(--light-2);
}
.c-tc-white {
    color: var(--white);
}
.c-tc-accent-1 {
    color: var(--accent-1);
}
.c-tc-accent-2 {
    color: var(--accent-2);
}
/* TEXT ALIGN & LETTER SPACING & LINE HEIGHT */
.c-ta-l {
    text-align: left;
}
.c-ta-r {
    text-align: right;
}
.c-ta-c {
    text-align: center;
}
.c-ta-j {
    text-align: justify;
}
.c-ls-tr {
    letter-spacing: -0.05em;
}
.c-ls-t {
    letter-spacing: -0.025em;
}
.c-ls-n {
    letter-spacing: 0px;
}
.c-ls-w {
    letter-spacing: 0.025em;
}
.c-ls-wr {
    letter-spacing: 0.05em;
}
.c-ls-wt {
    letter-spacing: 0.1em;
}
.c-lh-t {
    line-height: 1.25;
}
.c-lh-n {
    line-height: 1.5;
}
.c-lh-r {
    line-height: 1.68;
}
.c-lh-w {
    line-height: 2;
}
/* ICONS */
.c-ic-brand-1 {
    color: var(--brand-1);
}
.c-ic-brand-2 {
    color: var(--brand-2);
}
.c-ic-brand-3 {
    color: var(--brand-3);
}
.c-ic-brand-4 {
    color: var(--brand-4);
}
.c-ic-black {
    color: var(--black);
}
.c-ic-dark-1 {
    color: var(--dark-1);
}
.c-ic-dark-2 {
    color: var(--dark-2);
}
.c-ic-med {
    color: var(--medium);
}
.c-ic-light-1 {
    color: var(--light-1);
}
.c-ic-light-2 {
    color: var(--light-2);
}
.c-ic-white {
    color: var(--white);
}
.c-ic-accent-1 {
    color: var(--accent-1);
}
.c-ic-accent-2 {
    color: var(--accent-2);
}
.c-is-1 >svg {
    width: 8px;
    height: 8px;
}
.c-is-2 >svg {
    width: 16px;
    height: 16px;
}
.c-is-3 >svg {
    width: 24px;
    height: 24px;
}
.c-is-4 >svg {
    width: 32px;
    height: 32px;
}
.c-is-5 >svg {
    width: 40px;
    height: 40px;
}
.c-is-6 >svg {
    width: 48px;
    height: 48px;
}
.c-is-7 >svg {
    width: 56px;
    height: 56px;
}
.c-is-8 >svg {
    width: 64px;
    height: 64px;
}
.c-is-9 >svg {
    width: 72px;
    height: 72px;
}
.c-is-10 >svg {
    width: 80px;
    height: 80px;
}
/* BORDERS */
.c-bst-n {
    border-style: none;
}
.c-bst-s {
    border-style: solid;
}
.c-bst-da {
    border-style: dashed;
}
.c-bst-do {
    border-style: dotted;
}
.c-bw-xs {
    border-width: 1px;
}
.c-bw-sm {
    border-width: 2px;
}
.c-bw-md {
    border-width: 4px;
}
.c-bw-lg {
    border-width: 6px;
}
.c-bw-xl {
    border-width: 8px;
}
.c-br-n {
    border-radius: 0px;
}
.c-br-half {
    border-radius: 4px;
}
.c-br-1 {
    border-radius: 8px;
}
.c-br-2 {
    border-radius: 16px;
}
.c-br-3 {
    border-radius: 24px;
}
.c-br-100 {
    border-radius: 100%;
}
.c-bc-brand-1 {
    border-color: var(--brand-1);
}
.c-bc-brand-2 {
    border-color: var(--brand-2);
}
.c-bc-brand-3 {
    border-color: var(--brand-3);
}
.c-bc-brand-4 {
    border-color: var(--brand-4);
}
.c-bc-black {
    border-color: var(--black);
}
.c-bc-dark-1 {
    border-color: var(--dark-1);
}
.c-bc-dark-2 {
    border-color: var(--dark-2);
}
.c-bc-med {
    border-color: var(--medium);
}
.c-bc-light-1 {
    border-color: var(--light-1);
}
.c-bc-light-2 {
    border-color: var(--light-2);
}
.c-bc-white {
    border-color: var(--white);
}
.c-bc-accent-1 {
    border-color: var(--accent-1);
}
.c-bc-accent-2 {
    border-color: var(--accent-2);
}
/* BOX-SHADOW */
.c-bsh-sm {
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.2);
}
.c-bsh-md {
    box-shadow: 0px 8px 16px -4px rgba(0, 0, 0, 0.2);
}
.c-bsh-lg {
    box-shadow: 0px 16px 32px -8px rgba(0, 0, 0, 0.2);
}
/* AVATARS */
.c-avatar-xs {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}
.c-avatar-s {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.c-avatar-m {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}
.c-avatar-l {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}
.c-avatar-xl {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
}
/* SPACING - PADDING */
.c-p-0 {
    padding: 0px;
}
.c-p-half {
    padding: 4px;
}
.c-p-1 {
    padding: 8px;
}
.c-p-2 {
    padding: 16px;
}
.c-p-3 {
    padding: 24px;
}
.c-p-4 {
    padding: 32px;
}
.c-p-5 {
    padding: 40px;
}
.c-p-6 {
    padding: 48px;
}
.c-p-7 {
    padding: 56px;
}
.c-p-8 {
    padding: 64px;
}
.c-p-9 {
    padding: 72px;
}
.c-p-10 {
    padding: 80px;
}
.c-p-11 {
    padding: 88px;
}
.c-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}
.c-py-half {
    padding-top: 4px;
    padding-bottom: 4px;
}
.c-py-1 {
    padding-top: 8px;
    padding-bottom: 8px;
}
.c-py-2 {
    padding-top: 16px;
    padding-bottom: 16px;
}
.c-py-3 {
    padding-top: 24px;
    padding-bottom: 24px;
}
.c-py-4 {
    padding-top: 32px;
    padding-bottom: 32px;
}
.c-py-5 {
    padding-top: 40px;
    padding-bottom: 40px;
}
.c-py-6 {
    padding-top: 48px;
    padding-bottom: 48px;
}
.c-py-7 {
    padding-top: 56px;
    padding-bottom: 56px;
}
.c-py-8 {
    padding-top: 64px;
    padding-bottom: 64px;
}
.c-py-9 {
    padding-top: 72px;
    padding-bottom: 72px;
}
.c-py-10 {
    padding-top: 80px;
    padding-bottom: 80px;
}
.c-py-11 {
    padding-top: 88px;
    padding-bottom: 88px;
}
.c-px-0 {
    padding-left: 0px;
    padding-right: 0px;
}
.c-px-half {
    padding-left: 4px;
    padding-right: 4px;
}
.c-px-1 {
    padding-left: 8px;
    padding-right: 8px;
}
.c-px-2 {
    padding-left: 16px;
    padding-right: 16px;
}
.c-px-3 {
    padding-left: 24px;
    padding-right: 24px;
}
.c-px-4 {
    padding-left: 32px;
    padding-right: 32px;
}
.c-px-5 {
    padding-left: 40px;
    padding-right: 40px;
}
.c-px-6 {
    padding-left: 48px;
    padding-right: 48px;
}
.c-px-7 {
    padding-left: 56px;
    padding-right: 56px;
}
.c-px-8 {
    padding-left: 64px;
    padding-right: 64px;
}
.c-px-9 {
    padding-left: 72px;
    padding-right: 72px;
}
.c-px-10 {
    padding-left: 80px;
    padding-right: 80px;
}
.c-px-11 {
    padding-left: 88px;
    padding-right: 88px;
}
.c-pt-0 {
    padding-top: 0px;
}
.c-pt-half {
    padding-top: 4px;
}
.c-pt-1 {
    padding-top: 8px;
}
.c-pt-2 {
    padding-top: 16px;
}
.c-pt-3 {
    padding-top: 24px;
}
.c-pt-4 {
    padding-top: 32px;
}
.c-pt-5 {
    padding-top: 40px;
}
.c-pt-6 {
    padding-top: 48px;
}
.c-pt-7 {
    padding-top: 56px;
}
.c-pt-8 {
    padding-top: 64px;
}
.c-pt-9 {
    padding-top: 72px;
}
.c-pt-10 {
    padding-top: 80px;
}
.c-pt-11 {
    padding-top: 88px;
}
.c-pr-0 {
    padding-right: 0px;
}
.c-pr-half {
    padding-right: 4px;
}
.c-pr-1 {
    padding-right: 8px;
}
.c-pr-2 {
    padding-right: 16px;
}
.c-pr-3 {
    padding-right: 24px;
}
.c-pr-4 {
    padding-right: 32px;
}
.c-pr-5 {
    padding-right: 40px;
}
.c-pr-6 {
    padding-right: 48px;
}
.c-pr-7 {
    padding-right: 56px;
}
.c-pr-8 {
    padding-right: 64px;
}
.c-pr-9 {
    padding-right: 72px;
}
.c-pr-10 {
    padding-right: 88px;
}
.c-pr-11 {
    padding-right: 88px;
}
.c-pb-0 {
    padding-bottom: 0px;
}
.c-pb-half {
    padding-bottom: 4px;
}
.c-pb-1 {
    padding-bottom: 8px;
}
.c-pb-2 {
    padding-bottom: 16px;
}
.c-pb-3 {
    padding-bottom: 24px;
}
.c-pb-4 {
    padding-bottom: 32px;
}
.c-pb-5 {
    padding-bottom: 40px;
}
.c-pb-6 {
    padding-bottom: 48px;
}
.c-pb-7 {
    padding-bottom: 56px;
}
.c-pb-8 {
    padding-bottom: 64px;
}
.c-pb-9 {
    padding-bottom: 72px;
}
.c-pb-10 {
    padding-bottom: 80px;
}
.c-pb-11 {
    padding-bottom: 88px;
}
.c-pl-0 {
    padding-left: 0px;
}
.c-pl-half {
    padding-left: 4px;
}
.c-pl-1 {
    padding-left: 8px;
}
.c-pl-2 {
    padding-left: 16px;
}
.c-pl-3 {
    padding-left: 24px;
}
.c-pl-4 {
    padding-left: 32px;
}
.c-pl-5 {
    padding-left: 40px;
}
.c-pl-6 {
    padding-left: 48px;
}
.c-pl-7 {
    padding-left: 56px;
}
.c-pl-8 {
    padding-left: 64px;
}
.c-pl-9 {
    padding-left: 72px;
}
.c-pl-10 {
    padding-left: 80px;
}
.c-pl-11 {
    padding-left: 88px;
}
.c-pt-fl-xxl {
    padding-top: var(--fl-spacing-xxl);
}
.c-pt-fl-xl {
    padding-top: var(--fl-spacing-xl);
}
.c-pt-fl-l {
    padding-top: var(--fl-spacing-l);
}
.c-pt-fl-m {
    padding-top: var(--fl-spacing-m);
}
.c-pt-fl-s {
    padding-top: var(--fl-spacing-s);
}
.c-pt-fl-xs {
    padding-top: var(--fl-spacing-xs);
}
.c-pt-fl-xxs {
    padding-top: var(--fl-spacing-xxs);
}
.c-pr-fl-xxl {
    padding-right: var(--fl-spacing-xxl);
}
.c-pr-fl-xl {
    padding-right: var(--fl-spacing-xl);
}
.c-pr-fl-l {
    padding-right: var(--fl-spacing-l);
}
.c-pr-fl-m {
    padding-right: var(--fl-spacing-m);
}
.c-pr-fl-s {
    padding-right: var(--fl-spacing-s);
}
.c-pr-fl-xs {
    padding-right: var(--fl-spacing-xs);
}
.c-pr-fl-xxs {
    padding-right: var(--fl-spacing-xxs);
}
.c-pb-fl-xxl {
    padding-bottom: var(--fl-spacing-xxl);
}
.c-pb-fl-xl {
    padding-bottom: var(--fl-spacing-xl);
}
.c-pb-fl-l {
    padding-bottom: var(--fl-spacing-l);
}
.c-pb-fl-m {
    padding-bottom: var(--fl-spacing-m);
}
.c-pb-fl-s {
    padding-bottom: var(--fl-spacing-s);
}
.c-pb-fl-xs {
    padding-bottom: var(--fl-spacing-xs);
}
.c-pb-fl-xxs {
    padding-bottom: var(--fl-spacing-xxs);
}
.c-pl-fl-xxl {
    padding-left: var(--fl-spacing-xxl);
}
.c-pl-fl-xl {
    padding-left: var(--fl-spacing-xl);
}
.c-pl-fl-l {
    padding-left: var(--fl-spacing-l);
}
.c-pl-fl-m {
    padding-left: var(--fl-spacing-m);
}
.c-pl-fl-s {
    padding-left: var(--fl-spacing-s);
}
.c-pl-fl-xs {
    padding-left: var(--fl-spacing-xs);
}
.c-pl-fl-xxs {
    padding-left: var(--fl-spacing-xxs);
}
/* SPACING - MARGIN */
.c-m-0 {
    margin: 0px;
}
.c-m-half {
    margin: 4px;
}
.c-m-1 {
    margin: 8px;
}
.c-m-2 {
    margin: 16px;
}
.c-m-3 {
    margin: 24px;
}
.c-m-4 {
    margin: 32px;
}
.c-m-5 {
    margin: 40px;
}
.c-m-6 {
    margin: 48px;
}
.c-m-7 {
    margin: 56px;
}
.c-m-8 {
    margin: 64px;
}
.c-m-9 {
    margin: 72px;
}
.c-m-10 {
    margin: 80px;
}
.c-m-11 {
    margin: 88px;
}
.c-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
}
.c-my-half {
    margin-top: 4px;
    margin-bottom: 4px;
}
.c-my-1 {
    margin-top: 8px;
    margin-bottom: 8px;
}
.c-my-2 {
    margin-top: 16px;
    margin-bottom: 16px;
}
.c-my-3 {
    margin-top: 24px;
    margin-bottom: 24px;
}
.c-my-4 {
    margin-top: 32px;
    margin-bottom: 32px;
}
.c-my-5 {
    margin-top: 40px;
    margin-bottom: 40px;
}
.c-my-6 {
    margin-top: 48px;
    margin-bottom: 48px;
}
.c-my-7 {
    margin-top: 56px;
    margin-bottom: 56px;
}
.c-my-8 {
    margin-top: 64px;
    margin-bottom: 64px;
}
.c-my-9 {
    margin-top: 72px;
    margin-bottom: 72px;
}
.c-my-10 {
    margin-top: 80px;
    margin-bottom: 80px;
}
.c-my-11 {
    margin-top: 88px;
    margin-bottom: 88px;
}
.c-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
}
.c-mx-half {
    margin-left: 4px;
    margin-right: 4px;
}
.c-mx-1 {
    margin-left: 8px;
    margin-right: 8px;
}
.c-mx-2 {
    margin-left: 16px;
    margin-right: 16px;
}
.c-mx-3 {
    margin-left: 24px;
    margin-right: 24px;
}
.c-mx-4 {
    margin-left: 32px;
    margin-right: 32px;
}
.c-mx-5 {
    margin-left: 40px;
    margin-right: 40px;
}
.c-mx-6 {
    margin-left: 48px;
    margin-right: 48px;
}
.c-mx-7 {
    margin-left: 56px;
    margin-right: 56px;
}
.c-mx-8 {
    margin-left: 64px;
    margin-right: 64px;
}
.c-mx-9 {
    margin-left: 72px;
    margin-right: 72px;
}
.c-mx-10 {
    margin-left: 80px;
    margin-right: 80px;
}
.c-mx-11 {
    margin-left: 88px;
    margin-right: 88px;
}
.c-mx-auto{
    margin-left: auto;
    margin-right: auto;
}
.c-mt-0 {
    margin-top: 0px;
}
.c-mt-half {
    margin-top: 4px;
}
.c-mt-1 {
    margin-top: 8px;
}
.c-mt-2 {
    margin-top: 16px;
}
.c-mt-3 {
    margin-top: 24px;
}
.c-mt-4 {
    margin-top: 32px;
}
.c-mt-5 {
    margin-top: 40px;
}
.c-mt-6 {
    margin-top: 48px;
}
.c-mt-7 {
    margin-top: 56px;
}
.c-mt-8 {
    margin-top: 64px;
}
.c-mt-9 {
    margin-top: 72px;
}
.c-mt-10 {
    margin-top: 80px;
}
.c-mt-11 {
    margin-top: 88px;
}
.c-mr-0 {
    margin-right: 0px;
}
.c-mr-half {
    margin-right: 4px;
}
.c-mr-1 {
    margin-right: 8px;
}
.c-mr-2 {
    margin-right: 16px;
}
.c-mr-3 {
    margin-right: 24px;
}
.c-mr-4 {
    margin-right: 32px;
}
.c-mr-5 {
    margin-right: 40px;
}
.c-mr-6 {
    margin-right: 48px;
}
.c-mr-7 {
    margin-right: 56px;
}
.c-mr-8 {
    margin-right: 64px;
}
.c-mr-9 {
    margin-right: 72px;
}
.c-mr-10 {
    margin-right: 80px;
}
.c-mr-11 {
    margin-right: 88px;
}
.c-mb-0 {
    margin-bottom: 0px;
}
.c-mb-half {
    margin-bottom: 4px;
}
.c-mb-1 {
    margin-bottom: 8px;
}
.c-mb-2 {
    margin-bottom: 16px;
}
.c-mb-3 {
    margin-bottom: 24px;
}
.c-mb-4 {
    margin-bottom: 32px;
}
.c-mb-5 {
    margin-bottom: 40px;
}
.c-mb-6 {
    margin-bottom: 48px;
}
.c-mb-7 {
    margin-bottom: 56px;
}
.c-mb-8 {
    margin-bottom: 64px;
}
.c-mb-9 {
    margin-bottom: 72px;
}
.c-mb-10 {
    margin-bottom: 80px;
}
.c-mb-11 {
    margin-bottom: 88px;
}
.c-ml-0 {
    margin-left: 0px;
}
.c-ml-half {
    margin-left: 4px;
}
.c-ml-1 {
    margin-left: 8px;
}
.c-ml-2 {
    margin-left: 16px;
}
.c-ml-3 {
    margin-left: 24px;
}
.c-ml-4 {
    margin-left: 32px;
}
.c-ml-5 {
    margin-left: 40px;
}
.c-ml-6 {
    margin-left: 48px;
}
.c-ml-7 {
    margin-left: 56px;
}
.c-ml-8 {
    margin-left: 64px;
}
.c-ml-9 {
    margin-left: 72px;
}
.c-ml-10 {
    margin-left: 80px;
}
.c-ml-11 {
    margin-left: 88px;
}
.c-mt-neg-half {
    margin-top: -4px;
}
.c-mt-neg-1 {
    margin-top: -8px;
}
.c-mt-neg-2 {
    margin-top: -16px;
}
.c-mt-neg-3 {
    margin-top: -24px;
}
.c-mt-neg-4 {
    margin-top: -32px;
}
.c-mt-neg-5 {
    margin-top: -40px;
}
.c-mt-neg-6 {
    margin-top: -48px;
}
.c-mt-neg-7 {
    margin-top: -56px;
}
.c-mt-neg-8 {
    margin-top: -64px;
}
.c-mt-neg-9 {
    margin-top: -72px;
}
.c-mt-neg-10 {
    margin-top: -80px;
}
.c-mt-neg-11 {
    margin-top: -88px;
}
.c-mr-neg-half {
    margin-right: -4px;
}
.c-mr-neg-1 {
    margin-right: -8px;
}
.c-mr-neg-2 {
    margin-right: -16px;
}
.c-mr-neg-3 {
    margin-right: -24px;
}
.c-mr-neg-4 {
    margin-right: -32px;
}
.c-mr-neg-5 {
    margin-right: -40px;
}
.c-mr-neg-6 {
    margin-right: -48px;
}
.c-mr-neg-7 {
    margin-right: -56px;
}
.c-mr-neg-8 {
    margin-right: -64px;
}
.c-mr-neg-9 {
    margin-right: -72px;
}
.c-mr-neg-10 {
    margin-right: -80px;
}
.c-mr-neg-11 {
    margin-right: -88px;
}
.c-mb-neg-half {
    margin-bottom: -4px;
}
.c-mb-neg-1 {
    margin-bottom: -8px;
}
.c-mb-neg-2 {
    margin-bottom: -16px;
}
.c-mb-neg-3 {
    margin-bottom: -24px;
}
.c-mb-neg-4 {
    margin-bottom: -32px;
}
.c-mb-neg-5 {
    margin-bottom: -40px;
}
.c-mb-neg-6 {
    margin-bottom: -48px;
}
.c-mb-neg-7 {
    margin-bottom: -56px;
}
.c-mb-neg-8 {
    margin-bottom: -64px;
}
.c-mb-neg-9 {
    margin-bottom: -72px;
}
.c-mb-neg-10 {
    margin-bottom: -80px;
}
.c-mb-neg-11 {
    margin-bottom: -88px;
}
.c-ml-neg-half {
    margin-left: -4px;
}
.c-ml-neg-1 {
    margin-left: -8px;
}
.c-ml-neg-2 {
    margin-left: -16px;
}
.c-ml-neg-3 {
    margin-left: -24px;
}
.c-ml-neg-4 {
    margin-left: -32px;
}
.c-ml-neg-5 {
    margin-left: -40px;
}
.c-ml-neg-6 {
    margin-left: -48px;
}
.c-ml-neg-7 {
    margin-left: -56px;
}
.c-ml-neg-8 {
    margin-left: -64px;
}
.c-ml-neg-9 {
    margin-left: -72px;
}
.c-ml-neg-10 {
    margin-left: -80px;
}
.c-ml-neg-11 {
    margin-left: -88px;
}
.c-mt-fl-xxl {
    margin-top: var(--fl-spacing-xxl);
}
.c-mt-fl-xl {
    margin-top: var(--fl-spacing-xl);
}
.c-mt-fl-l {
    margin-top: var(--fl-spacing-l);
}
.c-mt-fl-m {
    margin-top: var(--fl-spacing-m);
}
.c-mt-fl-s {
    margin-top: var(--fl-spacing-s);
}
.c-mt-fl-xs {
    margin-top: var(--fl-spacing-xs);
}
.c-mt-fl-xxs {
    margin-top: var(--fl-spacing-xxs);
}
.c-mr-fl-xxl {
    margin-right: var(--fl-spacing-xxl);
}
.c-mr-fl-xl {
    margin-right: var(--fl-spacing-xl);
}
.c-mr-fl-l {
    margin-right: var(--fl-spacing-l);
}
.c-mr-fl-m {
    margin-right: var(--fl-spacing-m);
}
.c-mr-fl-s {
    margin-right: var(--fl-spacing-s);
}
.c-mr-fl-xs {
    margin-right: var(--fl-spacing-xs);
}
.c-mr-fl-xxs {
    margin-right: var(--fl-spacing-xxs);
}
.c-mb-fl-xxl {
    margin-bottom: var(--fl-spacing-xxl);
}
.c-mb-fl-xl {
    margin-bottom: var(--fl-spacing-xl);
}
.c-mb-fl-l {
    margin-bottom: var(--fl-spacing-l);
}
.c-mb-fl-m {
    margin-bottom: var(--fl-spacing-m);
}
.c-mb-fl-s {
    margin-bottom: var(--fl-spacing-s);
}
.c-mb-fl-xs {
    margin-bottom: var(--fl-spacing-xs);
}
.c-mb-fl-xxs {
    margin-bottom: var(--fl-spacing-xxs);
}
.c-ml-fl-xxl {
    margin-left: var(--fl-spacing-xxl);
}
.c-ml-fl-xl {
    margin-left: var(--fl-spacing-xl);
}
.c-ml-fl-l {
    margin-left: var(--fl-spacing-l);
}
.c-ml-fl-m {
    margin-left: var(--fl-spacing-m);
}
.c-ml-fl-s {
    margin-left: var(--fl-spacing-s);
}
.c-ml-fl-xs {
    margin-left: var(--fl-spacing-xs);
}
.c-ml-fl-xxs {
    margin-left: var(--fl-spacing-xxs);
}
/* FLEX CONTROLS */
.c-flex-r {
    display: flex;
    flex-direction: row;
}
.c-flex-c {
    display: flex;
    flex-direction: column;
}
@media only screen and (max-width: 1399px) {
    .c-flex-lg-r {
        flex-direction: row;
   }
    .c-flex-lg-c {
        flex-direction: column;
   }
}
@media only screen and (max-width: 991px) {
    .c-flex-md-r {
        flex-direction: row;
   }
    .c-flex-md-c {
        flex-direction: column;
   }
}
@media only screen and (max-width: 767px) {
    .c-flex-sm-r {
        flex-direction: row;
   }
    .c-flex-sm-c {
        flex-direction: column;
   }
}
@media only screen and (max-width: 479px) {
    .c-flex-xs-r {
        flex-direction: row;
   }
    .c-flex-xs-c {
        flex-direction: column;
   }
}
.c-flex-ai-fs {
    align-items: flex-start;
}
.c-flex-ai-c {
    align-items: center;
}
.c-flex-ai-fe {
    align-items: flex-end;
}
.c-flex-ai-sb {
    align-items: space-between;
}
.c-flex-ai-sa {
    align-items: space-around;
}
.c-flex-jc-fs {
    justify-content: flex-start;
}
.c-flex-jc-c {
    justify-content: center;
}
.c-flex-jc-fe {
    justify-content: flex-end;
}
.c-flex-jc-sb {
    justify-content: space-between;
}
.c-flex-jc-sa {
    justify-content: space-around;
}
/* IMG CLASSES */
.c-img-of-c {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.c-img-bg-abs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.c-pos-rel {
    position: relative;
}
.z-pos-1 {
    z-index: 1;
}
.z-pos-neg-1 {
    z-index: -1;
}
