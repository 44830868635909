/*--------------------------------------------------------------
# Global Scope CSS - Applied accross entire site
--------------------------------------------------------------*/

html, body{
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;

    &.plans {
        background-color: #f6f9fb;
    }
}

// Remove padding from SEOPress HTML breadcrumbs
ol.breadcrumb{
    padding-left: 0;
}

/* User Interaction Deferred Elements (only impacts mobile) */
html:not(.js-user-action){
    .js-defer-el{
        display:none!important;
    }
}


.app-container{
    max-width: 1330px;
    margin: 0 auto;
}

.app-body{
    width: 100%;
    text-align: center;
    min-height: 80vh;
    padding-top: 64px;
}

.content-header{
    margin-bottom: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .content-header-back-action{
        display: flex;
        flex-direction: column;
        text-align: left;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 19px;
        color: $black;
        width: 10%;

        a{
            text-decoration: none;
            font-weight: 900;
            color: $black;
        }

        span.content-header-quoter-ref {
            font-weight: 500;
            margin-top: 21px;
        }

    }
    
    .content-header-title{
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 80%;
    }

    &.full-width {
        .content-header-title{ 
            width: 100%;
        }
    }

    .content-header-next-action{
        @extend .content-header-back-action;
        text-align: right;
    }

    .content-subtext{
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
        font-size: 22px;
        line-height: 48px;
    }

    .content-title {
        font-weight: 700;
        font-family: 'Roboto', sans-serif;
        font-size: 36px;
        line-height: 48px;
    }
}

.conditional-group{
    display: none;
}


.dependents-list{

    .input-group:not(:first-child){
        display: none;
    }
}

.app-footer{
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    width: 100%;
   
    a.save-for-later {
        background: #FFE196;
        text-align: center;
        display: block;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: $black;
        text-decoration: none;
        padding: 21px;
    }

    p {
        margin: 0;
        background: #3F2A3F;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        padding: 21px;
    }
}

ul.declaration-authorization {
    padding-left: 20px;
    list-style: none;

    li{
        text-align: left;
        padding-left: 40px;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        margin-bottom: 40px;
        position: relative;

        &::before{
            content: " ";
            background: #FFC832;
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            position: absolute;
            left: -2rem;
            top: 5px;
        }
    }
}

.text-gray{
    color: $disabled;
}

.boxed-card{
    border: 2px solid $black;
    text-align: left;
    margin-bottom: 24px;

    .boxed-card-title{
        font-size: 24px;
        font-weight: 700;
        font-family: 'Roboto', sans-serif;
        border-bottom: 2px solid $black;
        padding: 13px 41px;
        text-transform: uppercase;
    }

    .boxed-card-content {
        padding: 21px 41px;
    }

    .inner-card {
        margin-bottom: 24px;
        margin-left: 25px;

        .inner-card-separator{
            border-bottom: 2px solid $disabled;
            margin: 16px 0;
            width: 100%;
        }

        .inner-card-title{
            font-size: 18px;
            font-weight: 700;
            font-family: 'Roboto', sans-serif;
            text-transform: uppercase;
            position: relative;

            .icon{
                position: absolute;
                left: -25px;
                color: $brand-4;
            }
        }
    }
}

.estimated-dates, .payment-details, .application-review{
    margin-bottom: 32px;
}

.declaration {
    margin-top: 80px;
    h3{
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;
        margin-top: 36px;
        margin-bottom: 60px;
    }
}

.monthly-premium {
    max-width: 320px;
    margin: 32px auto;

    .boxed-card-title{
        font-size: 18px;
    }

    .boxed-card-content{
        text-align: center;
        font-weight: 700;
    }
}

.payment-icons{
    display: flex;
    flex-direction: row;
    gap: 21px;

    span{
        display: flex;
        flex-direction: column;
        width: 60px;
        height: 40px;

        &.visa {
            background: url('/app/images/visa.svg') no-repeat;
        }

        &.mastercard {
            background: url('/app/images/mastercard.svg') no-repeat;
        }

        &.amex {
            background: url('/app/images/amex.svg') no-repeat;
        }
    }
}

.payment-methods-accordion{
    display: flex;
    border: 2px solid $black;
    flex-flow: column;

    .payment-methods-accordion-item {
        display: flex;
        flex-flow: column;

        .accordion-item-action {
            display: flex;
            flex-flow: row;
            padding: 26px;

            .input-group {
                margin-bottom: 0;
            }

            label{
                margin-bottom: 0;
            }
        }

        .accordion-item-content{
            display: flex;
            flex-flow: row;
            border-top: 2px solid #E0E0E0;
            padding: 26px;
            flex-wrap: wrap;

            &[data-relation="payor-payment-method-debit"], &[data-relation="payor-payment-method-card"] {
                display: none;
            }

            .joint-accounts-question, .choose-bank-account-question{
                font-weight: 700;
                margin-top: 0.5rem;
            }
        }
    }

    .payment-methods-accordion-item + .payment-methods-accordion-item{
        border-top: 2px solid $black;
    }
}

.plans-page{
    position: relative;
    
    a.edit-action {
        position:absolute;
        right: 25px;
        top: 25px;
        color: $black;
        text-decoration: none;
    }

    .primary-applicant{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 29px 35px;
        background-color: #ECF1F6;

        .primary-applicant-data{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 50%;

            span{
                text-align: left;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 12px;
                margin-bottom: 16px;
            }
        }
    
        .partner-applicant-data{
            display: flex;
            flex-direction: column;
            width: 50%;
            flex-wrap: wrap;

            span{
                text-align: left;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 12px;
                margin-bottom: 16px;
            }
        }
    
        .dependent-children-applicant-data{
            margin-top: 41px;
            display: flex;
            flex-direction: column;
            width: 100%;
            flex-wrap: nowrap;

            span{
                text-align: left;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 12px;
                margin-bottom: 16px;
            }
        }

        .data-row{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: start;
            gap: 50px;

            .data-column {
                display: flex;
                flex-direction: column;
                text-align: left;

                .data-column-title{
                    font-family: 'Roboto', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 16px;
                }

                .data-column-value{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 21px;
                }
            }
        }
    }

    .plans-filters{
        display: flex;
        direction: row;
        flex-wrap: wrap;
        margin-top: 48px;
        background-color: #FFFFFF;
        justify-content: space-between;
        padding: 29px 35px;

        .filter{
            display: flex;
            flex-direction: column;
        }

        input, select{
            font-size: 1.8rem;
        }

        select, input[type="text"]{
            border: none;
            appearance: button;
            outline: none;
            padding: 12px 0;
            color: $black;
            background: $accent-1;
    
            &:invalid{
                color: $disabled;
            }
    
            &:focus{
                color: $black;
            }
        }

        label {
            text-align: left;
        }
    }

    .plans-list{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 48px;
        gap:48px;

        .plan {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            background-color: $accent-1;

            &.recomended{
                background-color: #CFF0DB;

                .plan-description {
                    border-left: 2px solid #26AF5B !important;
                }

                .plan-apply{
                    background-color: #26AF5B !important;
                    color: #FFFFFF !important;
                }
            }

            .warning-alert{
                display: flex;
                flex-direction: row;
                width: 100%;
                background-color: #ECF1F6;
                color: $black;

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                padding: 15px 21px;

                align-content: center;
                align-items: center;
                justify-content: center;
            }

            .recomended-alert {
                display: flex;
                flex-direction: row;
                width: 100%;
                background-color: #26AF5B;

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 900;
                font-size: 18px;
                line-height: 21px;
                padding: 15px 21px;
                color: $accent-1;

                align-content: center;
                align-items: center;
            }

            .plan-content{
                display: flex;
                flex-direction: row;
                padding: 48px;
                gap: 25px;
                width: 100%;

                .plan-name-and-actions{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    width: 20%;
                    text-align: left;

                    .plan-compare{
                        text-decoration: none;
                        color: $black;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 24px;
                    }

                    .plan-package{
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                    }

                    .plan-more-details{
                        @extend .plan-compare;
                        margin-top: 45px;
                    }

                    ul.plan-features{
                        list-style: none;
                        padding: 0;
                        margin:0;

                        li {
                            background-color: #FFC832;
                            display: inline-block;
                            padding: 4px 9px;
                            color: #000000;

                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 16px;

                            margin-top: 24px;

                            i{
                                padding-right: 6px;
                            }

                        }
                    }

                    span.plan-title {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 32px;
                        display: block;
                        width: 100%;
                        margin-top: 20px;
                        margin-bottom: 14px;
                    }
                }

                .plan-description{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    width: 60%;
                    text-align: left;
                    border-left: 2px solid $black;
                    padding-left: 82px;

                    p{
                        margin-bottom: 0;
                        font-size: 16px;
                        font-weight: 700;
                    }

                    ul {
                        margin-top: 5px;
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 0;
                    }
                }

                .plan-price-and-actions{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    width: 20%;
                    text-align: center;
                    justify-content: center;
                    align-items: center;

                    .plan-price{
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 48px;
                        line-height: 56px;
                        text-align: center;

                        .plan-period {
                            clear: both;
                            display: block;
                            font-size: 24px;
                            line-height: 32px;
                        }
                    }

                    .plan-apply{
                        padding: 16px 18px;
                        display: flex;
                        flex-direction: column;
                        background-color: #FFC832;
                        color: #000000;

                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;

                        text-decoration: none;
                        text-transform: uppercase;

                        margin-top: 17px;
                        margin-bottom: 15px;
                    }

                    .plan-save-for-later {
                        text-decoration: none;
                        color: $black;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

ul.tabs-links{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 45px;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    flex-direction: row;
    width: 100%;

    li {
        a{
            text-decoration: none;
            color: $black;
            text-transform: uppercase;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 900;
            font-size: 16px;
            line-height: 19px;
            padding: 20px 25px; 

            &.active{
                border-bottom: 2px solid $black;
            }
        }
    }
}

.tabs-content {
    .tab{
        display: none;
        padding: 55px 110px;

        h2{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 900;
            font-size: 36px;
            line-height: 42px;
        }

        p{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 44px;
        }

        input[type="submit"]{
            padding: 18.5px 16px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 16px;
        }

        &.active {
            display: block;
        }
    }
}

ul.app-timeline {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    list-style-type: none;

    li.app-timeline__step{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 0;
        color: #ECF1F6;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 137.27%;


        &::before{
            position: absolute;
            content: "";
            border-bottom: 2px solid #ECF1F6;
            width: 100%;
            top: 12px;
            left: -50%;
            z-index: 0;
        }

        &:first-child{
            &::before{
                display: none;
            }
        }

        &::after{
            position: absolute;
            content: "";
            border-bottom: 2px solid #ECF1F6;
            width: 100%;
            top: 12px;
            left: 50%;
            z-index: 0;
        }

        &:last-child{
            &::before{
                left: -50%;
            }
            &::after{
                display: none;
            }
        }

        i.app-timeline__step__icon{
            width: 2.25rem;
            height: 2.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ECF1F6;
            border: 2px solid #ECF1F6;
            border-radius: 50%;
            z-index: 1;
            margin-bottom: 12px;
            font-size: 14px;
        }

        &.active{
            color: #000000;

            &::before{
                border-bottom: 2px solid #CFF0DB;
            }

            i.app-timeline__step__icon{
                background-color: #CFF0DB;
                color: #26AF5B;
                border: 2px solid #CFF0DB;

                &::before{
                    font-family: "Font Awesome 6 Free";
                    font-weight: 900;
                    font-style: normal;
                    font-size: 12px;
                    content: "\f00c";
                }
            }
        }
    }
}
