
.form-wrapper.health-dental-quote{
    max-width: 464px;
    margin: 0 auto;
    padding: 0 32px;
    // border: 1px solid #eee;
}



form{

    .input-row{
        display: flex;
        flex-direction: row;
        gap: 20px;

        &.no-gap{
            gap: 0;
        }
    }

    .input-boxed {
        border:  2px solid $black;
        padding: 20px 0;
        justify-content: center;
        margin-bottom: 25px;

        .input-group {
           
            margin-bottom: 0;

            label{
                margin-bottom: 0;
            }
        }
    }

    .input-group{
        text-align: left;
        margin-bottom: 4.9rem;

        &.no-mb{
            margin-bottom: 0;
        }
    }

    .input-error{
        display: none;
        color: $brand-3;
        font-size: 1.2rem;
        margin-top: 0.8rem;
        margin-bottom: -1em;
    }

    .input-group.invalid .input-error{
        display: block;
    }

    button{
        cursor: pointer;
    }

    label, .radio-group__label{
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.6rem;
        margin-bottom: 1rem;
        display: block;
    }

    input, select{
        font-size: 1.8rem;
    }

    select, input[type="text"], input[type="password"]{
        border: none;
        border-bottom: 2px solid $black;
        appearance: button;
        outline: none;
        padding: 12px 0;
        color: $black;
        background: $accent-1;

        &:invalid{
            color: $disabled;
        }

        &:focus{
            color: $black;
        }
    }

    input[type="submit"]{
        padding: 18.5px 16px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
    }

    .radio-group{
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .radio-group-item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;

        input[type="radio"]{
            position: absolute !important;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            width: 1px;
            border: 0;
            overflow: hidden;

            &:checked + label{
                background-color: $black;
	            box-shadow: none;
                color: $button-1;
            }
        }

        label{
            background-color: $brand-4;
            color: $black;
            font-size: 14px;
            line-height: 1;
            text-align: center;
            padding: 18.5px 16px;
            margin-right: -1px;
            transition: all 0.1s ease-in-out;

            &:hover{
                cursor: pointer;
            }
        }
    }
    
    .radio-group-list, .checkbox-group-list{
        display: flex;
        flex-direction: column;
    }

    .radio-group-list-item, .checkbox-group-list-item{
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        label {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        label::before{
            content: '';
            background: #fff;
            border: 4px solid #FFC832;
            display: flex;
            flex-direction: column;
            width: 20px;
            height: 20px;
            padding: 2px;
            margin-right: 10px;
            text-align: center;
            border-radius: 50%;
            justify-content: center;
        }

        input[type="radio"], input[type="checkbox"]{
            position: absolute !important;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            width: 1px;
            border: 0;
            overflow: hidden;

            &:checked + label::before{
                content: "\f00c";
                font-family: 'FontAwesome';
                background: #FFC832;
                color: #000;
            }
        }
    }

    .radio-group-list-h{
        @extend .radio-group-list;
        flex-direction: row;
    }

    .checkbox-group-list-h{
        @extend .checkbox-group-list;
        flex-direction: row;
    }

    label.applicant-agree-terms-label{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        text-transform: none;
    }

    .input-group-inverse {
        @extend .input-group;

        label{
            background-color: #fff;
            border: 2px solid $button-1;
        }

        input[type="radio"]{
            
            &:checked + label{
                background-color: $button-1;
                box-shadow: none;
                color: $black
            }
        }
    }
}

.multi-step-form{

    .app-step{
        display: none;

        .app-step-inner{
            max-width: 1080px;
            margin: 0 auto;
        }

        .app-step-title {
            text-transform: uppercase;
            text-align: left;
            font-weight: 700;
            line-height: 48px;
            font-family: 'Roboto', sans-serif;
        }

        h2.app-step-title {
            @extend .app-step-title;
            font-size: 36px;
            margin-top: 52px;
            margin-bottom: 79px;
        }

        h3.app-step-title {
            @extend .app-step-title;
            font-size: 24px;
        }

        .app-question {
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1.6rem;
            margin-bottom: 1rem;
            display: block;
        }

        &.active{
            display: block;
        }
    }

    label[for="home-postal-code"], label[for="payor-postal-code"], label[for="payor-postal-code-2"]{
        margin-top: -18px;
    }

    .step-nav{
        padding: 18.5px 60px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
    }
}