/*--------------------------------------------------------------
# Animation Styles
--------------------------------------------------------------*/

// @keyframes customElementorImageTada{
//     0% {
//         animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
//         clip-path: polygon(0 0, 100% 0%, 100% 0, 0 0);
//     }
//     35% {
//         clip-path: polygon(0 0, 100% 0%, 100% 35%, 0 48%);
//     }
//     65%{
//         clip-path: polygon(0 0, 100% 0%, 100% 76%, 0 65%);
//     }
//     100% {
//         animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
//         clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%);
//     }
// }

