/*--------------------------------------------------------------
# Typography Styles
--------------------------------------------------------------*/

@font-face {
    font-family: 'Roboto-Regular';
    src: local( 'Roboto' ),
         url('../fonts/Roboto-Regular.woff2') format('woff2'),
         url('../fonts/Roboto-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: local( 'Roboto' ),
         url('../fonts/Roboto-Medium.woff2') format('woff2'),
         url('../fonts/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: local( 'Roboto' ),
         url('../fonts/Roboto-Bold.woff2') format('woff2'),
         url('../fonts/Roboto-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

html{
    font-size: 62.5%;
}

body{
    @include fluid-val( 'font-size', 1.6rem, 1.8rem );
    font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6{
    line-height: 1.25em;
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.style-h{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    line-height: 1.25;
}

h1{
    @include fluid-val( font-size, 28px, 36px );
}
