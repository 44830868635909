/*--------------------------------------------------------------
# Footer Styles
--------------------------------------------------------------*/

.pdms-site-footer-copyright{
    font-size: 0.9rem;

    a{
        color: inherit!important;

        &:hover{
            color: red;
        }
    }
}

