/*!
Theme Name:    Propel - Oxy
Author:        Propel Digital Media Solutions
Author URI:    https://www.pdms.ca/
Description:   Custom WordPress Theme
Version:       1.0.0
License:       GNU General Public License v3 or later
License URI:   https://www.gnu.org/licenses/gpl-3.0.html
Text Domain:   propel-oxy
*/
/*--------------------------------------------------------------
# INSTRUCTIONS:
# -------------
# This is the master stylesheet. This is where you control what
# other SASS files will be included in the final CSS file. 
#
# NOTE:
# The only exception are the files under /page/templates/
# which are standalone files that will be created as separeate
# CSS stylesheets and only loaded on the pages they pertain to.
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Variables
--------------------------------------------------------------*/
/*--------------------------------------------------------------
* (DEPRECATED)
* Theme Colors & Variables - only use if not using Agency Base Plugin for Oxygen
--------------------------------------------------------------*/
/* Theme Colors */
/* Derivatives */
:root {
  --brand-1: #331832;
  --brand-1-lighten-80: #f6edf6;
  --brand-1-lighten-60: #d6a7d4;
  --brand-1-lighten-40: #b562b2;
  --brand-1-lighten-20: #783976;
  --brand-1-darken-20: black;
  --brand-1-darken-40: black;
  --brand-1-darken-60: black;
  --brand-1-darken-80: black;
  --brand-2: #26af5b;
  --brand-2-lighten-80: white;
  --brand-2-lighten-60: white;
  --brand-2-lighten-40: #b3eeca;
  --brand-2-lighten-20: #5fdc8f;
  --brand-2-darken-20: #145b2f;
  --brand-2-darken-40: #020704;
  --brand-2-darken-60: black;
  --brand-2-darken-80: black;
  --brand-3: #8224e3;
  --brand-3-lighten-80: white;
  --brand-3-lighten-60: white;
  --brand-3-lighten-40: #facdd4;
  --brand-3-lighten-20: #f07184;
  --brand-3-darken-20: #870e21;
  --brand-3-darken-40: #2b040a;
  --brand-3-darken-60: black;
  --brand-3-darken-80: black;
  --brand-4: #528cbf;
  --brand-4-lighten-80: white;
  --brand-4-lighten-60: white;
  --brand-4-lighten-40: #fff5db;
  --brand-4-lighten-20: #feda76;
  --brand-4-darken-20: #a77b01;
  --brand-4-darken-40: #423000;
  --brand-4-darken-60: black;
  --brand-4-darken-80: black;
  --accent-1: #fff;
  --accent-2: #fff;
  --gradient-1-c1: #fff;
  --gradient-1-c2: #fff;
  --gradient-2-c1: #fff;
  --gradient-2-c2: #fff;
  --button-1: #febe10;
  --button-1-lighten-20: #feda76;
  --button-1-lighten-10: #fecc43;
  --button-1-darken-10: #daa001;
  --button-1-darken-20: #a77b01;
  --button-1-text: #000;
  --button-2: #331832;
  --button-2-lighten-20: #783976;
  --button-2-lighten-10: #562854;
  --button-2-darken-10: #100810;
  --button-2-darken-20: black;
  --button-2-text: #fff;
  --button-3: #26af5b;
  --button-3-lighten-20: #5fdc8f;
  --button-3-lighten-10: #35d372;
  --button-3-darken-10: #1d8545;
  --button-3-darken-20: #145b2f;
  --button-3-text: #fff;
  --black: #000;
  --black-lighten-80: #cccccc;
  --black-lighten-60: #999999;
  --black-lighten-40: #666666;
  --black-lighten-20: #333333;
  --dark-1: #000;
  --dark-1-lighten-80: #cccccc;
  --dark-1-lighten-60: #999999;
  --dark-1-lighten-40: #666666;
  --dark-1-lighten-20: #333333;
  --dark-1-darken-20: black;
  --dark-1-darken-40: black;
  --dark-1-darken-60: black;
  --dark-1-darken-80: black;
  --dark-2: #000;
  --dark-2-lighten-80: #cccccc;
  --dark-2-lighten-60: #999999;
  --dark-2-lighten-40: #666666;
  --dark-2-lighten-20: #333333;
  --dark-2-darken-20: black;
  --dark-2-darken-40: black;
  --dark-2-darken-60: black;
  --dark-2-darken-80: black;
  --medium: #000;
  --medium-lighten-80: #cccccc;
  --medium-lighten-60: #999999;
  --medium-lighten-40: #666666;
  --medium-lighten-20: #333333;
  --medium-darken-20: black;
  --medium-darken-40: black;
  --medium-darken-60: black;
  --medium-darken-80: black;
  --light-1: #000;
  --light-1-lighten-80: #cccccc;
  --light-1-lighten-60: #999999;
  --light-1-lighten-40: #666666;
  --light-1-lighten-20: #333333;
  --light-1-darken-20: black;
  --light-1-darken-40: black;
  --light-1-darken-60: black;
  --light-1-darken-80: black;
  --light-2: #000;
  --light-2-lighten-80: #cccccc;
  --light-2-lighten-60: #999999;
  --light-2-lighten-40: #666666;
  --light-2-lighten-20: #333333;
  --light-2-darken-20: black;
  --light-2-darken-40: black;
  --light-2-darken-60: black;
  --light-2-darken-80: black;
  --white: #f2f2f2;
  --white-darken-80: #262626;
  --white-darken-60: #595959;
  --white-darken-40: #8c8c8c;
  --white-darken-20: #bfbfbf;
  --link-color: #0000ff;
  --link-hover-color: #0000a0;
  --disabled: #757575;
  --body: #222; }

/*--------------------------------------------------------------
# Core Framework
--------------------------------------------------------------*/
/* CSS GRID - COLUMNS */
.c-columns-2-auto {
  display: grid !important;
  grid-template-columns: auto auto;
  width: 100%; }

.c-columns-6 {
  display: grid !important;
  grid-template-columns: repeat(6, 1fr);
  width: 100%; }

.c-columns-5 {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr);
  width: 100%; }

.c-columns-4 {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  width: 100%; }

.c-columns-3 {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  width: 100%; }

.c-columns-2 {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  width: 100%; }

.c-columns-3-2 {
  display: grid !important;
  grid-template-columns: 3fr 2fr;
  width: 100%; }

.c-columns-2-3 {
  display: grid !important;
  grid-template-columns: 2fr 3fr;
  width: 100%; }

.c-columns-1-2 {
  display: grid !important;
  grid-template-columns: 1fr 2fr;
  width: 100%; }

.c-columns-2-1 {
  display: grid !important;
  grid-template-columns: 2fr 1fr;
  width: 100%; }

.c-columns-3-1 {
  display: grid !important;
  grid-template-columns: 3fr 1fr;
  width: 100%; }

.c-columns-1-3 {
  display: grid !important;
  grid-template-columns: 1fr 3fr;
  width: 100%; }

@media only screen and (max-width: 1399px) {
  .c-columns-xl-5 {
    grid-template-columns: repeat(5, 1fr); }
  .c-columns-xl-4 {
    grid-template-columns: repeat(4, 1fr); }
  .c-columns-xl-3 {
    grid-template-columns: repeat(3, 1fr); }
  .c-columns-xl-2 {
    grid-template-columns: repeat(2, 1fr); }
  .c-columns-xl-1 {
    grid-template-columns: 1fr; } }

@media only screen and (max-width: 991px) {
  .c-columns-l-4 {
    grid-template-columns: repeat(4, 1fr); }
  .c-columns-l-3 {
    grid-template-columns: repeat(3, 1fr); }
  .c-columns-l-2 {
    grid-template-columns: repeat(2, 1fr); }
  .c-columns-l-1 {
    grid-template-columns: 1fr; } }

@media only screen and (max-width: 767px) {
  .c-columns-m-3 {
    grid-template-columns: repeat(3, 1fr); }
  .c-columns-m-2 {
    grid-template-columns: repeat(2, 1fr); }
  .c-columns-m-1 {
    grid-template-columns: 1fr; } }

@media only screen and (max-width: 479px) {
  .c-columns-s-2 {
    grid-template-columns: repeat(2, 1fr); }
  .c-columns-s-1 {
    grid-template-columns: 1fr; } }

/* CSS GRID - GROW & GAPS*/
.c-row-grow {
  grid-row-start: span 2; }

.c-column-grow {
  grid-column-start: span 2; }

@media (max-width: 991px) {
  .c-row-grow {
    grid-row-start: span 1; }
  .c-column-grow {
    grid-column-start: span 1; } }

.c-columns-gap-xxl {
  grid-gap: 12vmin; }

.c-columns-gap-xl {
  grid-gap: 8vmin; }

.c-columns-gap-l {
  grid-gap: 5vmin; }

.c-columns-gap-m {
  grid-gap: 1.25rem; }

.c-columns-gap-s {
  grid-gap: 0.5rem; }

/* WIDTHS */
.c-w-100 {
  width: 100%; }

.c-w-90 {
  width: 90%; }

.c-w-80 {
  width: 80%; }

.c-w-75 {
  width: 75%; }

.c-w-70 {
  width: 70%; }

.c-w-60 {
  width: 60%; }

.c-w-50 {
  width: 50%; }

.c-w-40 {
  width: 40%; }

.c-w-33 {
  width: 33%; }

.c-w-30 {
  width: 30%; }

.c-w-25 {
  width: 25%; }

.c-w-20 {
  width: 20%; }

.c-w-10 {
  width: 10%; }

/* BACKGROUND COLORS */
.c-bg-gradient-1 {
  background-image: linear-gradient(90deg, var(--gradient-1-c1), var(--gradient-1-c2)); }

.c-bg-gradient-2 {
  background-image: linear-gradient(90deg, var(--gradient-2-c1), var(--gradient-2-c2)); }

.c-bg-brand-1 {
  background-color: var(--brand-1); }

.c-bg-brand-2 {
  background-color: var(--brand-2); }

.c-bg-brand-3 {
  background-color: var(--brand-3); }

.c-bg-brand-4 {
  background-color: var(--brand-4); }

.c-bg-black {
  background-color: var(--black); }

.c-bg-dark-1 {
  background-color: var(--dark-1); }

.c-bg-dark-2 {
  background-color: var(--dark-2); }

.c-bg-med {
  background-color: var(--medium); }

.c-bg-light-1 {
  background-color: var(--light-1); }

.c-bg-light-2 {
  background-color: var(--light-2); }

.c-bg-white {
  background-color: var(--white); }

.c-bg-accent-1 {
  background-color: var(--accent-1); }

.c-bg-accent-2 {
  background-color: var(--accent-2); }

/* HEADINGS */
.c-h1 {
  font-size: var(--title-size-1); }

.c-h2 {
  font-size: var(--title-size-2); }

.c-h3 {
  font-size: var(--title-size-3); }

.c-h4 {
  font-size: var(--title-size-4); }

.c-h5 {
  font-size: var(--title-size-5); }

.c-h6 {
  font-size: var(--title-size-6); }

/* FONT SIZE */
.c-fs-xxs {
  font-size: 0.625rem; }

.c-fs-xs {
  font-size: 0.75rem; }

.c-fs-s {
  font-size: 0.875rem; }

.c-fs-2 {
  font-size: 1rem; }

.c-fs-3 {
  font-size: 1.125rem; }

.c-fs-4 {
  font-size: 1.25rem; }

.c-fs-5 {
  font-size: 1.375rem; }

.c-fs-6 {
  font-size: 1.5rem; }

.c-fs-7 {
  font-size: 1.75rem; }

.c-fs-8 {
  font-size: 2rem; }

.c-fs-9 {
  font-size: 2.25rem; }

.c-fs-10 {
  font-size: 2.5rem; }

.c-fs-11 {
  font-size: 2.75rem; }

/* FONT WEIGHT */
.c-fw-100 {
  font-weight: 100; }

.c-fw-200 {
  font-weight: 200; }

.c-fw-300 {
  font-weight: 300; }

.c-fw-400 {
  font-weight: 400; }

.c-fw-500 {
  font-weight: 500; }

.c-fw-600 {
  font-weight: 600; }

.c-fw-700 {
  font-weight: 700; }

.c-fw-800 {
  font-weight: 800; }

.c-fw-900 {
  font-weight: 900; }

/* FONT STYLES */
.c-fs-under {
  text-decoration: underline; }

.c-fs-cap {
  text-transform: capitalize; }

.c-fs-lower {
  text-transform: lowercase; }

.c-fs-upper {
  text-transform: uppercase; }

.c-fs-strike {
  text-decoration: line-through; }

/* TEXT COLOR */
.c-tc-brand-1 {
  color: var(--brand-1); }

.c-tc-brand-2 {
  color: var(--brand-2); }

.c-tc-brand-3 {
  color: var(--brand-3); }

.c-tc-brand-4 {
  color: var(--brand-4); }

.c-tc-black {
  color: var(--black); }

.c-tc-dark-1 {
  color: var(--dark-1); }

.c-tc-dark-2 {
  color: var(--dark-2); }

.c-tc-med {
  color: var(--medium); }

.c-tc-light-1 {
  color: var(--light-1); }

.c-tc-light-2 {
  color: var(--light-2); }

.c-tc-white {
  color: var(--white); }

.c-tc-accent-1 {
  color: var(--accent-1); }

.c-tc-accent-2 {
  color: var(--accent-2); }

/* TEXT ALIGN & LETTER SPACING & LINE HEIGHT */
.c-ta-l {
  text-align: left; }

.c-ta-r {
  text-align: right; }

.c-ta-c {
  text-align: center; }

.c-ta-j {
  text-align: justify; }

.c-ls-tr {
  letter-spacing: -0.05em; }

.c-ls-t {
  letter-spacing: -0.025em; }

.c-ls-n {
  letter-spacing: 0px; }

.c-ls-w {
  letter-spacing: 0.025em; }

.c-ls-wr {
  letter-spacing: 0.05em; }

.c-ls-wt {
  letter-spacing: 0.1em; }

.c-lh-t {
  line-height: 1.25; }

.c-lh-n {
  line-height: 1.5; }

.c-lh-r {
  line-height: 1.68; }

.c-lh-w {
  line-height: 2; }

/* ICONS */
.c-ic-brand-1 {
  color: var(--brand-1); }

.c-ic-brand-2 {
  color: var(--brand-2); }

.c-ic-brand-3 {
  color: var(--brand-3); }

.c-ic-brand-4 {
  color: var(--brand-4); }

.c-ic-black {
  color: var(--black); }

.c-ic-dark-1 {
  color: var(--dark-1); }

.c-ic-dark-2 {
  color: var(--dark-2); }

.c-ic-med {
  color: var(--medium); }

.c-ic-light-1 {
  color: var(--light-1); }

.c-ic-light-2 {
  color: var(--light-2); }

.c-ic-white {
  color: var(--white); }

.c-ic-accent-1 {
  color: var(--accent-1); }

.c-ic-accent-2 {
  color: var(--accent-2); }

.c-is-1 > svg {
  width: 8px;
  height: 8px; }

.c-is-2 > svg {
  width: 16px;
  height: 16px; }

.c-is-3 > svg {
  width: 24px;
  height: 24px; }

.c-is-4 > svg {
  width: 32px;
  height: 32px; }

.c-is-5 > svg {
  width: 40px;
  height: 40px; }

.c-is-6 > svg {
  width: 48px;
  height: 48px; }

.c-is-7 > svg {
  width: 56px;
  height: 56px; }

.c-is-8 > svg {
  width: 64px;
  height: 64px; }

.c-is-9 > svg {
  width: 72px;
  height: 72px; }

.c-is-10 > svg {
  width: 80px;
  height: 80px; }

/* BORDERS */
.c-bst-n {
  border-style: none; }

.c-bst-s {
  border-style: solid; }

.c-bst-da {
  border-style: dashed; }

.c-bst-do {
  border-style: dotted; }

.c-bw-xs {
  border-width: 1px; }

.c-bw-sm {
  border-width: 2px; }

.c-bw-md {
  border-width: 4px; }

.c-bw-lg {
  border-width: 6px; }

.c-bw-xl {
  border-width: 8px; }

.c-br-n {
  border-radius: 0px; }

.c-br-half {
  border-radius: 4px; }

.c-br-1 {
  border-radius: 8px; }

.c-br-2 {
  border-radius: 16px; }

.c-br-3 {
  border-radius: 24px; }

.c-br-100 {
  border-radius: 100%; }

.c-bc-brand-1 {
  border-color: var(--brand-1); }

.c-bc-brand-2 {
  border-color: var(--brand-2); }

.c-bc-brand-3 {
  border-color: var(--brand-3); }

.c-bc-brand-4 {
  border-color: var(--brand-4); }

.c-bc-black {
  border-color: var(--black); }

.c-bc-dark-1 {
  border-color: var(--dark-1); }

.c-bc-dark-2 {
  border-color: var(--dark-2); }

.c-bc-med {
  border-color: var(--medium); }

.c-bc-light-1 {
  border-color: var(--light-1); }

.c-bc-light-2 {
  border-color: var(--light-2); }

.c-bc-white {
  border-color: var(--white); }

.c-bc-accent-1 {
  border-color: var(--accent-1); }

.c-bc-accent-2 {
  border-color: var(--accent-2); }

/* BOX-SHADOW */
.c-bsh-sm {
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.2); }

.c-bsh-md {
  box-shadow: 0px 8px 16px -4px rgba(0, 0, 0, 0.2); }

.c-bsh-lg {
  box-shadow: 0px 16px 32px -8px rgba(0, 0, 0, 0.2); }

/* AVATARS */
.c-avatar-xs {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover; }

.c-avatar-s {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover; }

.c-avatar-m {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover; }

.c-avatar-l {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover; }

.c-avatar-xl {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover; }

/* SPACING - PADDING */
.c-p-0 {
  padding: 0px; }

.c-p-half {
  padding: 4px; }

.c-p-1 {
  padding: 8px; }

.c-p-2 {
  padding: 16px; }

.c-p-3 {
  padding: 24px; }

.c-p-4 {
  padding: 32px; }

.c-p-5 {
  padding: 40px; }

.c-p-6 {
  padding: 48px; }

.c-p-7 {
  padding: 56px; }

.c-p-8 {
  padding: 64px; }

.c-p-9 {
  padding: 72px; }

.c-p-10 {
  padding: 80px; }

.c-p-11 {
  padding: 88px; }

.c-py-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.c-py-half {
  padding-top: 4px;
  padding-bottom: 4px; }

.c-py-1 {
  padding-top: 8px;
  padding-bottom: 8px; }

.c-py-2 {
  padding-top: 16px;
  padding-bottom: 16px; }

.c-py-3 {
  padding-top: 24px;
  padding-bottom: 24px; }

.c-py-4 {
  padding-top: 32px;
  padding-bottom: 32px; }

.c-py-5 {
  padding-top: 40px;
  padding-bottom: 40px; }

.c-py-6 {
  padding-top: 48px;
  padding-bottom: 48px; }

.c-py-7 {
  padding-top: 56px;
  padding-bottom: 56px; }

.c-py-8 {
  padding-top: 64px;
  padding-bottom: 64px; }

.c-py-9 {
  padding-top: 72px;
  padding-bottom: 72px; }

.c-py-10 {
  padding-top: 80px;
  padding-bottom: 80px; }

.c-py-11 {
  padding-top: 88px;
  padding-bottom: 88px; }

.c-px-0 {
  padding-left: 0px;
  padding-right: 0px; }

.c-px-half {
  padding-left: 4px;
  padding-right: 4px; }

.c-px-1 {
  padding-left: 8px;
  padding-right: 8px; }

.c-px-2 {
  padding-left: 16px;
  padding-right: 16px; }

.c-px-3 {
  padding-left: 24px;
  padding-right: 24px; }

.c-px-4 {
  padding-left: 32px;
  padding-right: 32px; }

.c-px-5 {
  padding-left: 40px;
  padding-right: 40px; }

.c-px-6 {
  padding-left: 48px;
  padding-right: 48px; }

.c-px-7 {
  padding-left: 56px;
  padding-right: 56px; }

.c-px-8 {
  padding-left: 64px;
  padding-right: 64px; }

.c-px-9 {
  padding-left: 72px;
  padding-right: 72px; }

.c-px-10 {
  padding-left: 80px;
  padding-right: 80px; }

.c-px-11 {
  padding-left: 88px;
  padding-right: 88px; }

.c-pt-0 {
  padding-top: 0px; }

.c-pt-half {
  padding-top: 4px; }

.c-pt-1 {
  padding-top: 8px; }

.c-pt-2 {
  padding-top: 16px; }

.c-pt-3 {
  padding-top: 24px; }

.c-pt-4 {
  padding-top: 32px; }

.c-pt-5 {
  padding-top: 40px; }

.c-pt-6 {
  padding-top: 48px; }

.c-pt-7 {
  padding-top: 56px; }

.c-pt-8 {
  padding-top: 64px; }

.c-pt-9 {
  padding-top: 72px; }

.c-pt-10 {
  padding-top: 80px; }

.c-pt-11 {
  padding-top: 88px; }

.c-pr-0 {
  padding-right: 0px; }

.c-pr-half {
  padding-right: 4px; }

.c-pr-1 {
  padding-right: 8px; }

.c-pr-2 {
  padding-right: 16px; }

.c-pr-3 {
  padding-right: 24px; }

.c-pr-4 {
  padding-right: 32px; }

.c-pr-5 {
  padding-right: 40px; }

.c-pr-6 {
  padding-right: 48px; }

.c-pr-7 {
  padding-right: 56px; }

.c-pr-8 {
  padding-right: 64px; }

.c-pr-9 {
  padding-right: 72px; }

.c-pr-10 {
  padding-right: 88px; }

.c-pr-11 {
  padding-right: 88px; }

.c-pb-0 {
  padding-bottom: 0px; }

.c-pb-half {
  padding-bottom: 4px; }

.c-pb-1 {
  padding-bottom: 8px; }

.c-pb-2 {
  padding-bottom: 16px; }

.c-pb-3 {
  padding-bottom: 24px; }

.c-pb-4 {
  padding-bottom: 32px; }

.c-pb-5 {
  padding-bottom: 40px; }

.c-pb-6 {
  padding-bottom: 48px; }

.c-pb-7 {
  padding-bottom: 56px; }

.c-pb-8 {
  padding-bottom: 64px; }

.c-pb-9 {
  padding-bottom: 72px; }

.c-pb-10 {
  padding-bottom: 80px; }

.c-pb-11 {
  padding-bottom: 88px; }

.c-pl-0 {
  padding-left: 0px; }

.c-pl-half {
  padding-left: 4px; }

.c-pl-1 {
  padding-left: 8px; }

.c-pl-2 {
  padding-left: 16px; }

.c-pl-3 {
  padding-left: 24px; }

.c-pl-4 {
  padding-left: 32px; }

.c-pl-5 {
  padding-left: 40px; }

.c-pl-6 {
  padding-left: 48px; }

.c-pl-7 {
  padding-left: 56px; }

.c-pl-8 {
  padding-left: 64px; }

.c-pl-9 {
  padding-left: 72px; }

.c-pl-10 {
  padding-left: 80px; }

.c-pl-11 {
  padding-left: 88px; }

.c-pt-fl-xxl {
  padding-top: var(--fl-spacing-xxl); }

.c-pt-fl-xl {
  padding-top: var(--fl-spacing-xl); }

.c-pt-fl-l {
  padding-top: var(--fl-spacing-l); }

.c-pt-fl-m {
  padding-top: var(--fl-spacing-m); }

.c-pt-fl-s {
  padding-top: var(--fl-spacing-s); }

.c-pt-fl-xs {
  padding-top: var(--fl-spacing-xs); }

.c-pt-fl-xxs {
  padding-top: var(--fl-spacing-xxs); }

.c-pr-fl-xxl {
  padding-right: var(--fl-spacing-xxl); }

.c-pr-fl-xl {
  padding-right: var(--fl-spacing-xl); }

.c-pr-fl-l {
  padding-right: var(--fl-spacing-l); }

.c-pr-fl-m {
  padding-right: var(--fl-spacing-m); }

.c-pr-fl-s {
  padding-right: var(--fl-spacing-s); }

.c-pr-fl-xs {
  padding-right: var(--fl-spacing-xs); }

.c-pr-fl-xxs {
  padding-right: var(--fl-spacing-xxs); }

.c-pb-fl-xxl {
  padding-bottom: var(--fl-spacing-xxl); }

.c-pb-fl-xl {
  padding-bottom: var(--fl-spacing-xl); }

.c-pb-fl-l {
  padding-bottom: var(--fl-spacing-l); }

.c-pb-fl-m {
  padding-bottom: var(--fl-spacing-m); }

.c-pb-fl-s {
  padding-bottom: var(--fl-spacing-s); }

.c-pb-fl-xs {
  padding-bottom: var(--fl-spacing-xs); }

.c-pb-fl-xxs {
  padding-bottom: var(--fl-spacing-xxs); }

.c-pl-fl-xxl {
  padding-left: var(--fl-spacing-xxl); }

.c-pl-fl-xl {
  padding-left: var(--fl-spacing-xl); }

.c-pl-fl-l {
  padding-left: var(--fl-spacing-l); }

.c-pl-fl-m {
  padding-left: var(--fl-spacing-m); }

.c-pl-fl-s {
  padding-left: var(--fl-spacing-s); }

.c-pl-fl-xs {
  padding-left: var(--fl-spacing-xs); }

.c-pl-fl-xxs {
  padding-left: var(--fl-spacing-xxs); }

/* SPACING - MARGIN */
.c-m-0 {
  margin: 0px; }

.c-m-half {
  margin: 4px; }

.c-m-1 {
  margin: 8px; }

.c-m-2 {
  margin: 16px; }

.c-m-3 {
  margin: 24px; }

.c-m-4 {
  margin: 32px; }

.c-m-5 {
  margin: 40px; }

.c-m-6 {
  margin: 48px; }

.c-m-7 {
  margin: 56px; }

.c-m-8 {
  margin: 64px; }

.c-m-9 {
  margin: 72px; }

.c-m-10 {
  margin: 80px; }

.c-m-11 {
  margin: 88px; }

.c-my-0 {
  margin-top: 0px;
  margin-bottom: 0px; }

.c-my-half {
  margin-top: 4px;
  margin-bottom: 4px; }

.c-my-1 {
  margin-top: 8px;
  margin-bottom: 8px; }

.c-my-2 {
  margin-top: 16px;
  margin-bottom: 16px; }

.c-my-3 {
  margin-top: 24px;
  margin-bottom: 24px; }

.c-my-4 {
  margin-top: 32px;
  margin-bottom: 32px; }

.c-my-5 {
  margin-top: 40px;
  margin-bottom: 40px; }

.c-my-6 {
  margin-top: 48px;
  margin-bottom: 48px; }

.c-my-7 {
  margin-top: 56px;
  margin-bottom: 56px; }

.c-my-8 {
  margin-top: 64px;
  margin-bottom: 64px; }

.c-my-9 {
  margin-top: 72px;
  margin-bottom: 72px; }

.c-my-10 {
  margin-top: 80px;
  margin-bottom: 80px; }

.c-my-11 {
  margin-top: 88px;
  margin-bottom: 88px; }

.c-mx-0 {
  margin-left: 0px;
  margin-right: 0px; }

.c-mx-half {
  margin-left: 4px;
  margin-right: 4px; }

.c-mx-1 {
  margin-left: 8px;
  margin-right: 8px; }

.c-mx-2 {
  margin-left: 16px;
  margin-right: 16px; }

.c-mx-3 {
  margin-left: 24px;
  margin-right: 24px; }

.c-mx-4 {
  margin-left: 32px;
  margin-right: 32px; }

.c-mx-5 {
  margin-left: 40px;
  margin-right: 40px; }

.c-mx-6 {
  margin-left: 48px;
  margin-right: 48px; }

.c-mx-7 {
  margin-left: 56px;
  margin-right: 56px; }

.c-mx-8 {
  margin-left: 64px;
  margin-right: 64px; }

.c-mx-9 {
  margin-left: 72px;
  margin-right: 72px; }

.c-mx-10 {
  margin-left: 80px;
  margin-right: 80px; }

.c-mx-11 {
  margin-left: 88px;
  margin-right: 88px; }

.c-mx-auto {
  margin-left: auto;
  margin-right: auto; }

.c-mt-0 {
  margin-top: 0px; }

.c-mt-half {
  margin-top: 4px; }

.c-mt-1 {
  margin-top: 8px; }

.c-mt-2 {
  margin-top: 16px; }

.c-mt-3 {
  margin-top: 24px; }

.c-mt-4 {
  margin-top: 32px; }

.c-mt-5 {
  margin-top: 40px; }

.c-mt-6 {
  margin-top: 48px; }

.c-mt-7 {
  margin-top: 56px; }

.c-mt-8 {
  margin-top: 64px; }

.c-mt-9 {
  margin-top: 72px; }

.c-mt-10 {
  margin-top: 80px; }

.c-mt-11 {
  margin-top: 88px; }

.c-mr-0 {
  margin-right: 0px; }

.c-mr-half {
  margin-right: 4px; }

.c-mr-1 {
  margin-right: 8px; }

.c-mr-2 {
  margin-right: 16px; }

.c-mr-3 {
  margin-right: 24px; }

.c-mr-4 {
  margin-right: 32px; }

.c-mr-5 {
  margin-right: 40px; }

.c-mr-6 {
  margin-right: 48px; }

.c-mr-7 {
  margin-right: 56px; }

.c-mr-8 {
  margin-right: 64px; }

.c-mr-9 {
  margin-right: 72px; }

.c-mr-10 {
  margin-right: 80px; }

.c-mr-11 {
  margin-right: 88px; }

.c-mb-0 {
  margin-bottom: 0px; }

.c-mb-half {
  margin-bottom: 4px; }

.c-mb-1 {
  margin-bottom: 8px; }

.c-mb-2 {
  margin-bottom: 16px; }

.c-mb-3 {
  margin-bottom: 24px; }

.c-mb-4 {
  margin-bottom: 32px; }

.c-mb-5 {
  margin-bottom: 40px; }

.c-mb-6 {
  margin-bottom: 48px; }

.c-mb-7 {
  margin-bottom: 56px; }

.c-mb-8 {
  margin-bottom: 64px; }

.c-mb-9 {
  margin-bottom: 72px; }

.c-mb-10 {
  margin-bottom: 80px; }

.c-mb-11 {
  margin-bottom: 88px; }

.c-ml-0 {
  margin-left: 0px; }

.c-ml-half {
  margin-left: 4px; }

.c-ml-1 {
  margin-left: 8px; }

.c-ml-2 {
  margin-left: 16px; }

.c-ml-3 {
  margin-left: 24px; }

.c-ml-4 {
  margin-left: 32px; }

.c-ml-5 {
  margin-left: 40px; }

.c-ml-6 {
  margin-left: 48px; }

.c-ml-7 {
  margin-left: 56px; }

.c-ml-8 {
  margin-left: 64px; }

.c-ml-9 {
  margin-left: 72px; }

.c-ml-10 {
  margin-left: 80px; }

.c-ml-11 {
  margin-left: 88px; }

.c-mt-neg-half {
  margin-top: -4px; }

.c-mt-neg-1 {
  margin-top: -8px; }

.c-mt-neg-2 {
  margin-top: -16px; }

.c-mt-neg-3 {
  margin-top: -24px; }

.c-mt-neg-4 {
  margin-top: -32px; }

.c-mt-neg-5 {
  margin-top: -40px; }

.c-mt-neg-6 {
  margin-top: -48px; }

.c-mt-neg-7 {
  margin-top: -56px; }

.c-mt-neg-8 {
  margin-top: -64px; }

.c-mt-neg-9 {
  margin-top: -72px; }

.c-mt-neg-10 {
  margin-top: -80px; }

.c-mt-neg-11 {
  margin-top: -88px; }

.c-mr-neg-half {
  margin-right: -4px; }

.c-mr-neg-1 {
  margin-right: -8px; }

.c-mr-neg-2 {
  margin-right: -16px; }

.c-mr-neg-3 {
  margin-right: -24px; }

.c-mr-neg-4 {
  margin-right: -32px; }

.c-mr-neg-5 {
  margin-right: -40px; }

.c-mr-neg-6 {
  margin-right: -48px; }

.c-mr-neg-7 {
  margin-right: -56px; }

.c-mr-neg-8 {
  margin-right: -64px; }

.c-mr-neg-9 {
  margin-right: -72px; }

.c-mr-neg-10 {
  margin-right: -80px; }

.c-mr-neg-11 {
  margin-right: -88px; }

.c-mb-neg-half {
  margin-bottom: -4px; }

.c-mb-neg-1 {
  margin-bottom: -8px; }

.c-mb-neg-2 {
  margin-bottom: -16px; }

.c-mb-neg-3 {
  margin-bottom: -24px; }

.c-mb-neg-4 {
  margin-bottom: -32px; }

.c-mb-neg-5 {
  margin-bottom: -40px; }

.c-mb-neg-6 {
  margin-bottom: -48px; }

.c-mb-neg-7 {
  margin-bottom: -56px; }

.c-mb-neg-8 {
  margin-bottom: -64px; }

.c-mb-neg-9 {
  margin-bottom: -72px; }

.c-mb-neg-10 {
  margin-bottom: -80px; }

.c-mb-neg-11 {
  margin-bottom: -88px; }

.c-ml-neg-half {
  margin-left: -4px; }

.c-ml-neg-1 {
  margin-left: -8px; }

.c-ml-neg-2 {
  margin-left: -16px; }

.c-ml-neg-3 {
  margin-left: -24px; }

.c-ml-neg-4 {
  margin-left: -32px; }

.c-ml-neg-5 {
  margin-left: -40px; }

.c-ml-neg-6 {
  margin-left: -48px; }

.c-ml-neg-7 {
  margin-left: -56px; }

.c-ml-neg-8 {
  margin-left: -64px; }

.c-ml-neg-9 {
  margin-left: -72px; }

.c-ml-neg-10 {
  margin-left: -80px; }

.c-ml-neg-11 {
  margin-left: -88px; }

.c-mt-fl-xxl {
  margin-top: var(--fl-spacing-xxl); }

.c-mt-fl-xl {
  margin-top: var(--fl-spacing-xl); }

.c-mt-fl-l {
  margin-top: var(--fl-spacing-l); }

.c-mt-fl-m {
  margin-top: var(--fl-spacing-m); }

.c-mt-fl-s {
  margin-top: var(--fl-spacing-s); }

.c-mt-fl-xs {
  margin-top: var(--fl-spacing-xs); }

.c-mt-fl-xxs {
  margin-top: var(--fl-spacing-xxs); }

.c-mr-fl-xxl {
  margin-right: var(--fl-spacing-xxl); }

.c-mr-fl-xl {
  margin-right: var(--fl-spacing-xl); }

.c-mr-fl-l {
  margin-right: var(--fl-spacing-l); }

.c-mr-fl-m {
  margin-right: var(--fl-spacing-m); }

.c-mr-fl-s {
  margin-right: var(--fl-spacing-s); }

.c-mr-fl-xs {
  margin-right: var(--fl-spacing-xs); }

.c-mr-fl-xxs {
  margin-right: var(--fl-spacing-xxs); }

.c-mb-fl-xxl {
  margin-bottom: var(--fl-spacing-xxl); }

.c-mb-fl-xl {
  margin-bottom: var(--fl-spacing-xl); }

.c-mb-fl-l {
  margin-bottom: var(--fl-spacing-l); }

.c-mb-fl-m {
  margin-bottom: var(--fl-spacing-m); }

.c-mb-fl-s {
  margin-bottom: var(--fl-spacing-s); }

.c-mb-fl-xs {
  margin-bottom: var(--fl-spacing-xs); }

.c-mb-fl-xxs {
  margin-bottom: var(--fl-spacing-xxs); }

.c-ml-fl-xxl {
  margin-left: var(--fl-spacing-xxl); }

.c-ml-fl-xl {
  margin-left: var(--fl-spacing-xl); }

.c-ml-fl-l {
  margin-left: var(--fl-spacing-l); }

.c-ml-fl-m {
  margin-left: var(--fl-spacing-m); }

.c-ml-fl-s {
  margin-left: var(--fl-spacing-s); }

.c-ml-fl-xs {
  margin-left: var(--fl-spacing-xs); }

.c-ml-fl-xxs {
  margin-left: var(--fl-spacing-xxs); }

/* FLEX CONTROLS */
.c-flex-r {
  display: flex;
  flex-direction: row; }

.c-flex-c {
  display: flex;
  flex-direction: column; }

@media only screen and (max-width: 1399px) {
  .c-flex-lg-r {
    flex-direction: row; }
  .c-flex-lg-c {
    flex-direction: column; } }

@media only screen and (max-width: 991px) {
  .c-flex-md-r {
    flex-direction: row; }
  .c-flex-md-c {
    flex-direction: column; } }

@media only screen and (max-width: 767px) {
  .c-flex-sm-r {
    flex-direction: row; }
  .c-flex-sm-c {
    flex-direction: column; } }

@media only screen and (max-width: 479px) {
  .c-flex-xs-r {
    flex-direction: row; }
  .c-flex-xs-c {
    flex-direction: column; } }

.c-flex-ai-fs {
  align-items: flex-start; }

.c-flex-ai-c {
  align-items: center; }

.c-flex-ai-fe {
  align-items: flex-end; }

.c-flex-ai-sb {
  align-items: space-between; }

.c-flex-ai-sa {
  align-items: space-around; }

.c-flex-jc-fs {
  justify-content: flex-start; }

.c-flex-jc-c {
  justify-content: center; }

.c-flex-jc-fe {
  justify-content: flex-end; }

.c-flex-jc-sb {
  justify-content: space-between; }

.c-flex-jc-sa {
  justify-content: space-around; }

/* IMG CLASSES */
.c-img-of-c {
  object-fit: cover;
  width: 100%;
  height: 100%; }

.c-img-bg-abs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.c-pos-rel {
  position: relative; }

.z-pos-1 {
  z-index: 1; }

.z-pos-neg-1 {
  z-index: -1; }

/*--------------------------------------------------------------
# Helpers
--------------------------------------------------------------*/
/*--------------------------------------------------------------
* Mixin for applying Fuild Values to CSS properties like font-size or line-height
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Media Query Responsive Breakpoint Variables
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Creates a media query with a min and max pixel range
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Creates a media query with a max pixel value
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Creates a media query with a min pixel value
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Animation Styles
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Typography Styles
--------------------------------------------------------------*/
@font-face {
  font-family: 'Roboto-Regular';
  src: local("Roboto"), url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Medium';
  src: local("Roboto"), url("../fonts/Roboto-Medium.woff2") format("woff2"), url("../fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Bold';
  src: local("Roboto"), url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

html {
  font-size: 62.5%; }

body {
  font-size: clamp(1.6rem, calc( 1.6rem  + (( 1vw - 4.85px) * 0.0197 )), 1.8rem);
  font-family: 'Roboto', sans-serif; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.25em;
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-family: 'Roboto', sans-serif;
  font-weight: 700; }

.style-h {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  line-height: 1.25; }

h1 {
  font-size: clamp(28px, calc( 28px  + (( 1vw - 4.85px) * 0.78818 )), 36px); }

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
/*--------------------------------------------------------------
 BASE BUTTON STYLES 
 --------------------------------------------------------------
 Used in other modules using the @extend rule to pull in styles
 and keep button styles consistent across all components.
--------------------------------------------------------------*/
:root {
  --button-sm-padding-v: 0.4em;
  --button-sm-padding-h: 1.3em;
  --button-md-padding-v: 0.65em;
  --button-md-padding-h: 1.6em;
  --button-lg-padding-v: 0.85em;
  --button-lg-padding-h: 1.85em;
  --button-min-width: 220px; }

.theme-button, .button-1, a.button-1, button.button-1, .button-2, a.button-2, button.button-2, .button-3, a.button-3, button.button-3 {
  transition: all 0.3s;
  border: none;
  border-radius: 0;
  font-family: 'Muli', sans-serif;
  font-weight: 800;
  font-size: var(--button-text-size); }

.button-1, a.button-1, button.button-1 {
  background-color: var(--button-1);
  color: var(--button-1-text); }
  .button-1:hover {
    background-color: var(--button-1-lighten-10);
    color: var(--button-1-text); }

.button-2, a.button-2, button.button-2 {
  background-color: var(--button-2);
  color: var(--button-2-text); }
  .button-2:hover {
    background-color: var(--button-2-lighten-10);
    color: var(--button-2-text); }

.button-3, a.button-3, button.button-3 {
  background-color: var(--button-3);
  color: var(--button-3-text); }
  .button-3:hover {
    background-color: var(--button-3-lighten-10);
    color: var(--button-3-text); }

a.button-sm, button.button-sm {
  padding: var(--button-sm-padding-v) var(--button-sm-padding-h); }

a.button-md, button.button-md {
  padding: var(--button-md-padding-v) var(--button-md-padding-h); }

a.button-lg, button.button-lg {
  padding: var(--button-lg-padding-v) var(--button-lg-padding-h); }

a.button-min-width, button.button-min-width {
  width: 100%;
  max-width: var(--button-min-width); }

/*--------------------------------------------------------------
# Footer Styles
--------------------------------------------------------------*/
.pdms-site-footer-copyright {
  font-size: 0.9rem; }
  .pdms-site-footer-copyright a {
    color: inherit !important; }
    .pdms-site-footer-copyright a:hover {
      color: red; }

/*--------------------------------------------------------------
# Header Styles
--------------------------------------------------------------*/
.app-header {
  display: grid;
  grid-template-columns: 250px 1fr 250px;
  padding-top: 30px; }

.header__logo {
  width: 100%;
  max-width: 240px;
  display: flex;
  align-items: center;
  max-height: 51px; }

.header__timeline, .app-timeline {
  display: flex;
  flex-direction: row;
  width: 100%; }

.header__timeline {
  align-items: center; }

.app-timeline {
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-around; }

.header__contact-info {
  display: flex;
  flex-direction: column;
  align-items: center; }

.contact-info__number {
  font-size: 2.6rem;
  text-decoration: none; }

.form-wrapper.health-dental-quote {
  max-width: 464px;
  margin: 0 auto;
  padding: 0 32px; }

form .input-row {
  display: flex;
  flex-direction: row;
  gap: 20px; }
  form .input-row.no-gap {
    gap: 0; }

form .input-boxed {
  border: 2px solid #000;
  padding: 20px 0;
  justify-content: center;
  margin-bottom: 25px; }
  form .input-boxed .input-group, form .input-boxed .input-group-inverse {
    margin-bottom: 0; }
    form .input-boxed .input-group label, form .input-boxed .input-group-inverse label {
      margin-bottom: 0; }

form .input-group, form .input-group-inverse {
  text-align: left;
  margin-bottom: 4.9rem; }
  form .input-group.no-mb, form .no-mb.input-group-inverse {
    margin-bottom: 0; }

form .input-error {
  display: none;
  color: #e31837;
  font-size: 1.2rem;
  margin-top: 0.8rem;
  margin-bottom: -1em; }

form .input-group.invalid .input-error, form .invalid.input-group-inverse .input-error {
  display: block; }

form button {
  cursor: pointer; }

form label, form .radio-group__label {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  display: block; }

form input, form select {
  font-size: 1.8rem; }

form select, form input[type="text"], form input[type="password"] {
  border: none;
  border-bottom: 2px solid #000;
  appearance: button;
  outline: none;
  padding: 12px 0;
  color: #000;
  background: #fff; }
  form select:invalid, form input[type="text"]:invalid, form input[type="password"]:invalid {
    color: #757575; }
  form select:focus, form input[type="text"]:focus, form input[type="password"]:focus {
    color: #000; }

form input[type="submit"] {
  padding: 18.5px 16px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px; }

form .radio-group {
  display: flex;
  flex-direction: row;
  gap: 20px; }

form .radio-group-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center; }
  form .radio-group-item input[type="radio"] {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden; }
    form .radio-group-item input[type="radio"]:checked + label {
      background-color: #000;
      box-shadow: none;
      color: #febe10; }
  form .radio-group-item label {
    background-color: #febe10;
    color: #000;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 18.5px 16px;
    margin-right: -1px;
    transition: all 0.1s ease-in-out; }
    form .radio-group-item label:hover {
      cursor: pointer; }

form .radio-group-list, form .radio-group-list-h, form .checkbox-group-list, form .checkbox-group-list-h {
  display: flex;
  flex-direction: column; }

form .radio-group-list-item, form .checkbox-group-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center; }
  form .radio-group-list-item label, form .checkbox-group-list-item label {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center; }
  form .radio-group-list-item label::before, form .checkbox-group-list-item label::before {
    content: '';
    background: #fff;
    border: 4px solid #FFC832;
    display: flex;
    flex-direction: column;
    width: 20px;
    height: 20px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
    border-radius: 50%;
    justify-content: center; }
  form .radio-group-list-item input[type="radio"], form .radio-group-list-item input[type="checkbox"], form .checkbox-group-list-item input[type="radio"], form .checkbox-group-list-item input[type="checkbox"] {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden; }
    form .radio-group-list-item input[type="radio"]:checked + label::before, form .radio-group-list-item input[type="checkbox"]:checked + label::before, form .checkbox-group-list-item input[type="radio"]:checked + label::before, form .checkbox-group-list-item input[type="checkbox"]:checked + label::before {
      content: "\f00c";
      font-family: 'FontAwesome';
      background: #FFC832;
      color: #000; }

form .radio-group-list-h {
  flex-direction: row; }

form .checkbox-group-list-h {
  flex-direction: row; }

form label.applicant-agree-terms-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-transform: none; }

form .input-group-inverse label {
  background-color: #fff;
  border: 2px solid #febe10; }

form .input-group-inverse input[type="radio"]:checked + label {
  background-color: #febe10;
  box-shadow: none;
  color: #000; }

.multi-step-form .app-step {
  display: none; }
  .multi-step-form .app-step .app-step-inner {
    max-width: 1080px;
    margin: 0 auto; }
  .multi-step-form .app-step .app-step-title, .multi-step-form .app-step h2.app-step-title, .multi-step-form .app-step h3.app-step-title {
    text-transform: uppercase;
    text-align: left;
    font-weight: 700;
    line-height: 48px;
    font-family: 'Roboto', sans-serif; }
  .multi-step-form .app-step h2.app-step-title {
    font-size: 36px;
    margin-top: 52px;
    margin-bottom: 79px; }
  .multi-step-form .app-step h3.app-step-title {
    font-size: 24px; }
  .multi-step-form .app-step .app-question {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    display: block; }
  .multi-step-form .app-step.active {
    display: block; }

.multi-step-form label[for="home-postal-code"], .multi-step-form label[for="payor-postal-code"], .multi-step-form label[for="payor-postal-code-2"] {
  margin-top: -18px; }

.multi-step-form .step-nav {
  padding: 18.5px 60px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px; }

.note-box {
  padding: 26px 52px;
  text-align: left; }
  .note-box p {
    margin: 0;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    color: #000000; }
  .note-box.note-green {
    background-color: #CFF0DB; }

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center; }

.modal__container {
  background-color: #fff;
  padding: 20px 30px;
  width: 640px;
  max-height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative; }
  .modal__container.large {
    width: 1330px; }

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box; }

.modal__close {
  background: transparent;
  border: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer; }

.modal__header .modal__close:before {
  content: "\2715";
  font-size: 16px; }

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8); }

.modal__btn {
  font-size: .875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: .25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform .25s ease-out;
  transition: transform .25s ease-out;
  transition: transform .25s ease-out,-webkit-transform .25s ease-out; }

.modal__btn:focus, .modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05); }

.modal__btn-primary {
  background-color: #00449e;
  color: #fff; }

/**************************\
    Demo Animation Style
  \**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes mmfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes mmslideIn {
  from {
    transform: translateY(15%); }
  to {
    transform: translateY(0); } }

@keyframes mmslideOut {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-10%); } }

.micromodal-slide {
  display: none; }

.micromodal-slide.is-open {
  display: block; }

/*--------------------------------------------------------------
# Theme Elements
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Global Page Styles
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Global Scope CSS - Applied accross entire site
--------------------------------------------------------------*/
html, body {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0; }
  html.plans, body.plans {
    background-color: #f6f9fb; }

ol.breadcrumb {
  padding-left: 0; }

/* User Interaction Deferred Elements (only impacts mobile) */
html:not(.js-user-action) .js-defer-el {
  display: none !important; }

.app-container {
  max-width: 1330px;
  margin: 0 auto; }

.app-body {
  width: 100%;
  text-align: center;
  min-height: 80vh;
  padding-top: 64px; }

.content-header {
  margin-bottom: 52px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .content-header .content-header-back-action, .content-header .content-header-next-action {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    width: 10%; }
    .content-header .content-header-back-action a, .content-header .content-header-next-action a {
      text-decoration: none;
      font-weight: 900;
      color: #000; }
    .content-header .content-header-back-action span.content-header-quoter-ref, .content-header .content-header-next-action span.content-header-quoter-ref {
      font-weight: 500;
      margin-top: 21px; }
  .content-header .content-header-title {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 80%; }
  .content-header.full-width .content-header-title {
    width: 100%; }
  .content-header .content-header-next-action {
    text-align: right; }
  .content-header .content-subtext {
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    line-height: 48px; }
  .content-header .content-title {
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    line-height: 48px; }

.conditional-group {
  display: none; }

.dependents-list .input-group:not(:first-child), .dependents-list form .input-group-inverse:not(:first-child), form .dependents-list .input-group-inverse:not(:first-child) {
  display: none; }

.app-footer {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  width: 100%; }
  .app-footer a.save-for-later {
    background: #FFE196;
    text-align: center;
    display: block;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #000;
    text-decoration: none;
    padding: 21px; }
  .app-footer p {
    margin: 0;
    background: #3F2A3F;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 21px; }

ul.declaration-authorization {
  padding-left: 20px;
  list-style: none; }
  ul.declaration-authorization li {
    text-align: left;
    padding-left: 40px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin-bottom: 40px;
    position: relative; }
    ul.declaration-authorization li::before {
      content: " ";
      background: #FFC832;
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      position: absolute;
      left: -2rem;
      top: 5px; }

.text-gray {
  color: #757575; }

.boxed-card {
  border: 2px solid #000;
  text-align: left;
  margin-bottom: 24px; }
  .boxed-card .boxed-card-title {
    font-size: 24px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    border-bottom: 2px solid #000;
    padding: 13px 41px;
    text-transform: uppercase; }
  .boxed-card .boxed-card-content {
    padding: 21px 41px; }
  .boxed-card .inner-card {
    margin-bottom: 24px;
    margin-left: 25px; }
    .boxed-card .inner-card .inner-card-separator {
      border-bottom: 2px solid #757575;
      margin: 16px 0;
      width: 100%; }
    .boxed-card .inner-card .inner-card-title {
      font-size: 18px;
      font-weight: 700;
      font-family: 'Roboto', sans-serif;
      text-transform: uppercase;
      position: relative; }
      .boxed-card .inner-card .inner-card-title .icon {
        position: absolute;
        left: -25px;
        color: #febe10; }

.estimated-dates, .payment-details, .application-review {
  margin-bottom: 32px; }

.declaration {
  margin-top: 80px; }
  .declaration h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    margin-top: 36px;
    margin-bottom: 60px; }

.monthly-premium {
  max-width: 320px;
  margin: 32px auto; }
  .monthly-premium .boxed-card-title {
    font-size: 18px; }
  .monthly-premium .boxed-card-content {
    text-align: center;
    font-weight: 700; }

.payment-icons {
  display: flex;
  flex-direction: row;
  gap: 21px; }
  .payment-icons span {
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 40px; }
    .payment-icons span.visa {
      background: url("/app/images/visa.svg") no-repeat; }
    .payment-icons span.mastercard {
      background: url("/app/images/mastercard.svg") no-repeat; }
    .payment-icons span.amex {
      background: url("/app/images/amex.svg") no-repeat; }

.payment-methods-accordion {
  display: flex;
  border: 2px solid #000;
  flex-flow: column; }
  .payment-methods-accordion .payment-methods-accordion-item {
    display: flex;
    flex-flow: column; }
    .payment-methods-accordion .payment-methods-accordion-item .accordion-item-action {
      display: flex;
      flex-flow: row;
      padding: 26px; }
      .payment-methods-accordion .payment-methods-accordion-item .accordion-item-action .input-group, .payment-methods-accordion .payment-methods-accordion-item .accordion-item-action form .input-group-inverse, form .payment-methods-accordion .payment-methods-accordion-item .accordion-item-action .input-group-inverse {
        margin-bottom: 0; }
      .payment-methods-accordion .payment-methods-accordion-item .accordion-item-action label {
        margin-bottom: 0; }
    .payment-methods-accordion .payment-methods-accordion-item .accordion-item-content {
      display: flex;
      flex-flow: row;
      border-top: 2px solid #E0E0E0;
      padding: 26px;
      flex-wrap: wrap; }
      .payment-methods-accordion .payment-methods-accordion-item .accordion-item-content[data-relation="payor-payment-method-debit"], .payment-methods-accordion .payment-methods-accordion-item .accordion-item-content[data-relation="payor-payment-method-card"] {
        display: none; }
      .payment-methods-accordion .payment-methods-accordion-item .accordion-item-content .joint-accounts-question, .payment-methods-accordion .payment-methods-accordion-item .accordion-item-content .choose-bank-account-question {
        font-weight: 700;
        margin-top: 0.5rem; }
  .payment-methods-accordion .payment-methods-accordion-item + .payment-methods-accordion-item {
    border-top: 2px solid #000; }

.plans-page {
  position: relative; }
  .plans-page a.edit-action {
    position: absolute;
    right: 25px;
    top: 25px;
    color: #000;
    text-decoration: none; }
  .plans-page .primary-applicant {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 29px 35px;
    background-color: #ECF1F6; }
    .plans-page .primary-applicant .primary-applicant-data {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 50%; }
      .plans-page .primary-applicant .primary-applicant-data span {
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 16px; }
    .plans-page .primary-applicant .partner-applicant-data {
      display: flex;
      flex-direction: column;
      width: 50%;
      flex-wrap: wrap; }
      .plans-page .primary-applicant .partner-applicant-data span {
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 16px; }
    .plans-page .primary-applicant .dependent-children-applicant-data {
      margin-top: 41px;
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-wrap: nowrap; }
      .plans-page .primary-applicant .dependent-children-applicant-data span {
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 16px; }
    .plans-page .primary-applicant .data-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: start;
      gap: 50px; }
      .plans-page .primary-applicant .data-row .data-column {
        display: flex;
        flex-direction: column;
        text-align: left; }
        .plans-page .primary-applicant .data-row .data-column .data-column-title {
          font-family: 'Roboto', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 16px; }
        .plans-page .primary-applicant .data-row .data-column .data-column-value {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 21px; }
  .plans-page .plans-filters {
    display: flex;
    direction: row;
    flex-wrap: wrap;
    margin-top: 48px;
    background-color: #FFFFFF;
    justify-content: space-between;
    padding: 29px 35px; }
    .plans-page .plans-filters .filter {
      display: flex;
      flex-direction: column; }
    .plans-page .plans-filters input, .plans-page .plans-filters select {
      font-size: 1.8rem; }
    .plans-page .plans-filters select, .plans-page .plans-filters input[type="text"] {
      border: none;
      appearance: button;
      outline: none;
      padding: 12px 0;
      color: #000;
      background: #fff; }
      .plans-page .plans-filters select:invalid, .plans-page .plans-filters input[type="text"]:invalid {
        color: #757575; }
      .plans-page .plans-filters select:focus, .plans-page .plans-filters input[type="text"]:focus {
        color: #000; }
    .plans-page .plans-filters label {
      text-align: left; }
  .plans-page .plans-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 48px;
    gap: 48px; }
    .plans-page .plans-list .plan {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      background-color: #fff; }
      .plans-page .plans-list .plan.recomended {
        background-color: #CFF0DB; }
        .plans-page .plans-list .plan.recomended .plan-description {
          border-left: 2px solid #26AF5B !important; }
        .plans-page .plans-list .plan.recomended .plan-apply {
          background-color: #26AF5B !important;
          color: #FFFFFF !important; }
      .plans-page .plans-list .plan .warning-alert {
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: #ECF1F6;
        color: #000;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        padding: 15px 21px;
        align-content: center;
        align-items: center;
        justify-content: center; }
      .plans-page .plans-list .plan .recomended-alert {
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: #26AF5B;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 21px;
        padding: 15px 21px;
        color: #fff;
        align-content: center;
        align-items: center; }
      .plans-page .plans-list .plan .plan-content {
        display: flex;
        flex-direction: row;
        padding: 48px;
        gap: 25px;
        width: 100%; }
        .plans-page .plans-list .plan .plan-content .plan-name-and-actions {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          width: 20%;
          text-align: left; }
          .plans-page .plans-list .plan .plan-content .plan-name-and-actions .plan-compare, .plans-page .plans-list .plan .plan-content .plan-name-and-actions .plan-more-details {
            text-decoration: none;
            color: #000;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px; }
          .plans-page .plans-list .plan .plan-content .plan-name-and-actions .plan-package {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px; }
          .plans-page .plans-list .plan .plan-content .plan-name-and-actions .plan-more-details {
            margin-top: 45px; }
          .plans-page .plans-list .plan .plan-content .plan-name-and-actions ul.plan-features {
            list-style: none;
            padding: 0;
            margin: 0; }
            .plans-page .plans-list .plan .plan-content .plan-name-and-actions ul.plan-features li {
              background-color: #FFC832;
              display: inline-block;
              padding: 4px 9px;
              color: #000000;
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 16px;
              margin-top: 24px; }
              .plans-page .plans-list .plan .plan-content .plan-name-and-actions ul.plan-features li i {
                padding-right: 6px; }
          .plans-page .plans-list .plan .plan-content .plan-name-and-actions span.plan-title {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            display: block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 14px; }
        .plans-page .plans-list .plan .plan-content .plan-description {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          width: 60%;
          text-align: left;
          border-left: 2px solid #000;
          padding-left: 82px; }
          .plans-page .plans-list .plan .plan-content .plan-description p {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 700; }
          .plans-page .plans-list .plan .plan-content .plan-description ul {
            margin-top: 5px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0; }
        .plans-page .plans-list .plan .plan-content .plan-price-and-actions {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          width: 20%;
          text-align: center;
          justify-content: center;
          align-items: center; }
          .plans-page .plans-list .plan .plan-content .plan-price-and-actions .plan-price {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 56px;
            text-align: center; }
            .plans-page .plans-list .plan .plan-content .plan-price-and-actions .plan-price .plan-period {
              clear: both;
              display: block;
              font-size: 24px;
              line-height: 32px; }
          .plans-page .plans-list .plan .plan-content .plan-price-and-actions .plan-apply {
            padding: 16px 18px;
            display: flex;
            flex-direction: column;
            background-color: #FFC832;
            color: #000000;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            text-decoration: none;
            text-transform: uppercase;
            margin-top: 17px;
            margin-bottom: 15px; }
          .plans-page .plans-list .plan .plan-content .plan-price-and-actions .plan-save-for-later {
            text-decoration: none;
            color: #000;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px; }

ul.tabs-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 45px;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  flex-direction: row;
  width: 100%; }
  ul.tabs-links li a {
    text-decoration: none;
    color: #000;
    text-transform: uppercase;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    padding: 20px 25px; }
    ul.tabs-links li a.active {
      border-bottom: 2px solid #000; }

.tabs-content .tab {
  display: none;
  padding: 55px 110px; }
  .tabs-content .tab h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 42px; }
  .tabs-content .tab p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 44px; }
  .tabs-content .tab input[type="submit"] {
    padding: 18.5px 16px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px; }
  .tabs-content .tab.active {
    display: block; }

ul.app-timeline {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  list-style-type: none; }
  ul.app-timeline li.app-timeline__step {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    color: #ECF1F6;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 137.27%; }
    ul.app-timeline li.app-timeline__step::before {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ECF1F6;
      width: 100%;
      top: 12px;
      left: -50%;
      z-index: 0; }
    ul.app-timeline li.app-timeline__step:first-child::before {
      display: none; }
    ul.app-timeline li.app-timeline__step::after {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ECF1F6;
      width: 100%;
      top: 12px;
      left: 50%;
      z-index: 0; }
    ul.app-timeline li.app-timeline__step:last-child::before {
      left: -50%; }
    ul.app-timeline li.app-timeline__step:last-child::after {
      display: none; }
    ul.app-timeline li.app-timeline__step i.app-timeline__step__icon {
      width: 2.25rem;
      height: 2.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ECF1F6;
      border: 2px solid #ECF1F6;
      border-radius: 50%;
      z-index: 1;
      margin-bottom: 12px;
      font-size: 14px; }
    ul.app-timeline li.app-timeline__step.active {
      color: #000000; }
      ul.app-timeline li.app-timeline__step.active::before {
        border-bottom: 2px solid #CFF0DB; }
      ul.app-timeline li.app-timeline__step.active i.app-timeline__step__icon {
        background-color: #CFF0DB;
        color: #26AF5B;
        border: 2px solid #CFF0DB; }
        ul.app-timeline li.app-timeline__step.active i.app-timeline__step__icon::before {
          font-family: "Font Awesome 6 Free";
          font-weight: 900;
          font-style: normal;
          font-size: 12px;
          content: "\f00c"; }
