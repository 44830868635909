/*--------------------------------------------------------------
 BASE BUTTON STYLES 
 --------------------------------------------------------------
 Used in other modules using the @extend rule to pull in styles
 and keep button styles consistent across all components.
--------------------------------------------------------------*/

:root{
    --button-sm-padding-v: 0.4em;
    --button-sm-padding-h: 1.3em; 

    --button-md-padding-v: 0.65em; 
    --button-md-padding-h: 1.6em;

    --button-lg-padding-v: 0.85em; 
    --button-lg-padding-h: 1.85em; 

    --button-min-width: 220px;
}



.theme-button{
    transition: all 0.3s;
    border: none;
    border-radius: 0;
    font-family: 'Muli', sans-serif;
    font-weight: 800;
    font-size: var(--button-text-size);
}

.button-1{
    @extend .theme-button;

    background-color: var(--button-1);
    color: var(--button-1-text);

    &:hover{
        background-color: var(--button-1-lighten-10);
        color: var(--button-1-text);
    }
}

.button-2{
    @extend .theme-button;

    background-color: var(--button-2);
    color: var(--button-2-text);

    &:hover{
        background-color: var(--button-2-lighten-10);
        color: var(--button-2-text);
    }
}

.button-3{
    @extend .theme-button;

    background-color: var(--button-3);
    color: var(--button-3-text);

    &:hover{
        background-color: var(--button-3-lighten-10);
        color: var(--button-3-text);
    }
}


a, button{

    &.button-1{
        @extend .button-1;
    }

    &.button-2{
        @extend .button-2;
    }

    &.button-3{
        @extend .button-3;
    }

    &.button-sm {
        padding: var(--button-sm-padding-v) var(--button-sm-padding-h);
    }
    
    &.button-md {
        padding: var(--button-md-padding-v) var(--button-md-padding-h);
    }
    
    &.button-lg{
        padding: var(--button-lg-padding-v) var(--button-lg-padding-h);
    }
    
    &.button-min-width{
        width: 100%;
        max-width: var(--button-min-width);
    }
}