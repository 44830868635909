/*!
Theme Name:    Propel - Oxy
Author:        Propel Digital Media Solutions
Author URI:    https://www.pdms.ca/
Description:   Custom WordPress Theme
Version:       1.0.0
License:       GNU General Public License v3 or later
License URI:   https://www.gnu.org/licenses/gpl-3.0.html
Text Domain:   propel-oxy
*/


/*--------------------------------------------------------------
# INSTRUCTIONS:
# -------------
# This is the master stylesheet. This is where you control what
# other SASS files will be included in the final CSS file. 
#
# NOTE:
# The only exception are the files under /page/templates/
# which are standalone files that will be created as separeate
# CSS stylesheets and only loaded on the pages they pertain to.
--------------------------------------------------------------*/



/*--------------------------------------------------------------
# Variables
--------------------------------------------------------------*/
@import 'core/variables';


/*--------------------------------------------------------------
# Core Framework
--------------------------------------------------------------*/
@import 'core/framework';

/*--------------------------------------------------------------
# Helpers
--------------------------------------------------------------*/
@import 'helpers/mixins';
@import 'helpers/mediaqueries';


/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@import 'components/animations';


/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
@import 'components/typography';


/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
@import 'components/buttons';
@import 'components/footer';
@import 'components/header';
@import 'components/forms';
@import 'components/notes';
@import 'components/micro_modal';


/*--------------------------------------------------------------
# Theme Elements
--------------------------------------------------------------*/



/*--------------------------------------------------------------
# Global Page Styles
--------------------------------------------------------------*/
@import 'pages/app';
